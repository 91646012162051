import { useTranslation } from 'react-i18next'

// comonents
import { CurrencyDisplay } from 'components/display/Currency'
import {
    InfoItem,
    // LargeInfoItem,
    PanelCol,
    PanelRow,
    PanelHr,
    Panel
} from 'components/Card/Panel'
import {
    // ClickableAddress,
    ClickableHeight,
    SendRecv
} from 'components/Card/Transaction'
import { TxInfoPanel as TxInfoPanelLoading } from './PanelLoading'
import { displayHexInDecimal } from 'utils/unitConversion'

// api
import { TransactionDetail } from 'api/types'

interface TxInfoPaneOKProps {
    txInfo: TransactionDetail
    className?: string
}

const TxInfoPanelOK = ({ txInfo, className }: TxInfoPaneOKProps) => {
    const { t } = useTranslation()

    return (
        <Panel className={className}>
            <PanelRow>
                <PanelCol>
                    <InfoItem
                        desc={t('txinfo_panel_height')}
                        content={
                            <ClickableHeight
                                blockHeight={txInfo?.block.number}
                            />
                        }
                    />
                    {/* <InfoItem
                        desc={t('txinfo_panel_timestamp')}
                        // content={txInfo?.timestamp?.toLocaleString()}
                        content={txInfo?.block.timestamp}
                    /> */}

                    {/* <InfoItem desc={'Status'} content={txInfo?.status} /> */}
                    <InfoItem
                        desc={t('txinfo_panel_amount')}
                        content={<CurrencyDisplay wei={txInfo?.value} />}
                    />
                    <InfoItem
                        desc={t('txinfo_panel_nonce')}
                        content={displayHexInDecimal(txInfo?.nonce)}
                    />
                </PanelCol>
                <PanelCol>
                    <InfoItem
                        desc={'Transaction Index'}
                        content={displayHexInDecimal(txInfo?.transactionIndex)}
                    />
                    <InfoItem
                        desc={'Gas'}
                        content={displayHexInDecimal(txInfo?.gas)}
                    />
                    <InfoItem
                        desc={'Gas Price'}
                        content={displayHexInDecimal(txInfo?.gasPrice)}
                    />
                </PanelCol>
            </PanelRow>
            <PanelHr />
            <PanelRow>
                <PanelCol className="">
                    <InfoItem desc={'v'} content={txInfo?.v} showCopyIcon />
                    <InfoItem desc={'r'} content={txInfo?.r} showCopyIcon />
                    <InfoItem desc={'s'} content={txInfo?.s} showCopyIcon />
                    <InfoItem desc={'Input'} content={txInfo?.input} />
                </PanelCol>
            </PanelRow>

            <PanelHr />

            <PanelRow>
                <PanelCol>
                    <SendRecv
                        from={[{ account: txInfo.from, amount: txInfo.value }]}
                        to={[{ account: txInfo.to, amount: txInfo.value }]}
                    />
                </PanelCol>
            </PanelRow>
        </Panel>
    )
}

interface TxInfoPaneProps {
    txInfo: TransactionDetail | undefined
    isLoading?: boolean
    className?: string
}

const TxInfoPanel = ({
    txInfo,
    isLoading = false,
    className = '',
    ...props
}: TxInfoPaneProps) =>
    isLoading || !txInfo ? (
        <TxInfoPanelLoading className={className} />
    ) : (
        <TxInfoPanelOK className={className} txInfo={txInfo} {...props} />
    )

export { InfoItem, TxInfoPanel }
