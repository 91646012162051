import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { PATH } from 'constants/url'

// components
import Section from 'components/layout/Section'
import Helmet from 'components/Helmet'
import NetworkStats from './NetworkStats'
import { BlockListDisplay } from 'components/DataList/BlockList'
import { TxListDisplay } from 'components/DataList/TxList'
import { GhostButton } from 'components/Button'
// import LiveBlock from './LiveBlock'

// api
import {
    useCandidateBlock,
    useLatestFinalizedBlock,
    useLatestTransaction
} from './hooks'

const NUM_PREVIEW_ITEMS = 5

const H2: React.FC = ({ children }) => (
    <h2
        className="
            font-bold
            text-xl md:text-4xl"
    >
        {children}
    </h2>
)

const DashboardPage = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()

    const {
        block: candidateBlock
        // isLoading // TODO: component for loading
    } = useCandidateBlock()
    const {
        blocks: finalizedBlocks,
        isLoading: isLoadingFinalizedBlocks, // TODO: component for loading
        update: updateFinalizedBlocks
    } = useLatestFinalizedBlock(NUM_PREVIEW_ITEMS)
    const { transactions, isLoading: isLoadingTxs } =
        useLatestTransaction(NUM_PREVIEW_ITEMS)

    // update Blocklist when new block finalized
    useEffect(() => {
        if (!isLoadingFinalizedBlocks) updateFinalizedBlocks()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [candidateBlock?.number])

    return (
        <>
            <Helmet
                title={t('dash_meta_title')}
                description={t('dash_meta_description')}
            />

            <Section className="mt-6 md:mt-12">
                <H2>{t('dash_network_stats')}</H2>
                <NetworkStats className="mt-2 md:mt-7" />
            </Section>

            {/* <Section className="mt-8 md:mt-12">
                <H2>Live Block Stats</H2>
                <LiveBlock block={candidateBlock} className="mt-6" />
            </Section> */}
            <Section className="mt-8 md:mt-12">
                <H2>{t('dash_latest_blocks')}</H2>
                <BlockListDisplay
                    className="mt-3 md:mt-6"
                    blocks={finalizedBlocks}
                    isLoading={isLoadingFinalizedBlocks && !finalizedBlocks} // don't show loading screen during update
                    loadingItems={NUM_PREVIEW_ITEMS}
                />
                <div className="mt-4 md:mt-7 flex justify-center md:justify-end">
                    <GhostButton onClick={() => navigate(PATH.BLOCK_LIST)}>
                        View more blocks
                    </GhostButton>
                </div>
            </Section>

            <Section className="mt-8 md:mt-7">
                <H2>{t('dash_latest_transaxtions')}</H2>
                <TxListDisplay
                    className="mt-3 md:mt-6"
                    txs={transactions}
                    isLoading={isLoadingTxs && !transactions} // don't show loading screen during update
                    loadingItems={NUM_PREVIEW_ITEMS}
                />
                <div className="mt-4 md:mt-7 flex justify-center md:justify-end">
                    <GhostButton onClick={() => navigate(PATH.BLOCK_LIST)}>
                        View more blocks
                    </GhostButton>
                </div>
            </Section>
        </>
    )
}

export default DashboardPage
