import { hydrate, render } from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import 'index.css'
import 'normalize.css'
import 'i18n'
import '@fontsource/open-sans/400.css'
import '@fontsource/open-sans/600.css'
import '@fontsource/open-sans/700.css'
import reportWebVitals from 'reportWebVitals'

import App from 'App'

const rootElement = document.getElementById('root')

if (rootElement === null) {
    throw new Error('#root not found')
}

if (rootElement.hasChildNodes()) {
    hydrate(
        <Router>
            <App />
        </Router>,
        rootElement
    )
} else {
    render(
        <Router>
            <App />
        </Router>,
        rootElement
    )
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
