import { lazy, Suspense } from 'react'

import { useTranslation } from 'react-i18next'

// components
import Section from 'components/layout/Section'
import Helmet from 'components/Helmet'

const TxList = lazy(() => import('components/DataList/TxList'))

const TxListPage = () => {
    const { t } = useTranslation()

    return (
        <>
            <Helmet
                title={t('txlist_meta_title')}
                description={t('txlist_meta_description')}
            />
            <Section className="mt-5 md:mt-12">
                <h1
                    className="
                        font-bold
                        text-base md:text-4xl
                        my-0 ml-3.5 md:ml-0"
                >
                    Latest transactions
                </h1>
                <Suspense fallback={false}>
                    <TxList className="mt-2 md:mt-7" />
                </Suspense>
            </Section>
        </>
    )
}

export default TxListPage
