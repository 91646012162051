import { lazy, Suspense } from 'react'
import { BlockDetail } from 'api/types'
import AggSignatureLoading from './Loading'

interface AggSignatureProps {
    blockInfo: BlockDetail | undefined
    className?: string
}

const AggregateSignature = lazy(() => import('./AggregateSignature'))

const AggSignature = ({ blockInfo, className = '' }: AggSignatureProps) => (
    <Suspense fallback={<AggSignatureLoading className={className} />}>
        {blockInfo === undefined ? (
            <AggSignatureLoading className={className} />
        ) : (
            <AggregateSignature blockInfo={blockInfo} className={className} />
        )}
    </Suspense>
)

export default AggSignature
