import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { PATH } from 'constants/url'

// components
import Section from 'components/layout/Section'
import Helmet from 'components/Helmet'
import { TxInfoPanel } from 'pages/TxInfo/Panel'
import IconTitle from 'components/Card/IconTitle'

// assets
import { ReactComponent as SpinArrow } from 'images/circled-spin_arrow.svg'

// api
import { useTransactionDetail } from './hooks'

const TxInfoPage = () => {
    const params = useParams()
    const { t } = useTranslation()
    const navigate = useNavigate()

    const { isLoading, hasError, transactionDetail } = useTransactionDetail(
        params.txHash
    )

    useEffect(() => {
        if (!isLoading && !hasError && !transactionDetail) {
            navigate(PATH.NOT_FOUND, { replace: true })
        }
    }, [transactionDetail, hasError, isLoading, navigate])

    return (
        <>
            <Helmet
                title={t('txinfo_meta_title')}
                description={t('txinfo_meta_description', {
                    txId: transactionDetail?.hash
                })}
            />

            <Section className="mt-5 md:mt-12">
                <IconTitle
                    icon={SpinArrow}
                    title={t('txinfo_title')}
                    desc={params.txHash}
                />

                <TxInfoPanel
                    className="mt-4 md:mt-6"
                    txInfo={transactionDetail}
                    isLoading={isLoading}
                />
            </Section>
        </>
    )
}

export default TxInfoPage
