import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import ClassHelper from 'utils/ClassHelper'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { PATH } from 'constants/url'

// assets
import { ReactComponent as Search } from 'images/icon-search.svg'
import { ReactComponent as AngleDown } from 'images/icon-angle_down.svg'
import { ReactComponent as AngleUp } from 'images/icon-angle_up.svg'
import { ReactComponent as Check } from 'images/icon-check.svg'

enum SEARCH_TERMS {
    'BLOCK_HEIGHT',
    'BLOCK_HASH',
    'ADDRESS',
    'TX_ID'
}
const I18N_MAPPING_SEARCH_TERMS: { [key in SEARCH_TERMS]: string } = {
    [SEARCH_TERMS.BLOCK_HEIGHT]: 'search_term_block_height',
    [SEARCH_TERMS.BLOCK_HASH]: 'search_term_block_hash',
    [SEARCH_TERMS.ADDRESS]: 'search_term_account_address',
    [SEARCH_TERMS.TX_ID]: 'search_term_tx_hash'
}

interface DropdownItemProps {
    selected?: boolean
    onMouseDown?: (event: React.MouseEvent<HTMLLIElement>) => void
    onMouseUp?: () => void
    term?: string
    className?: string
}

const DropdownItem: React.FC<DropdownItemProps> = ({
    children,
    selected = false,
    onMouseDown,
    onMouseUp,
    term,
    className = ''
}) => (
    <li
        className={`
            h-9 p-2 min-w-max
            font-semibold text-sm
            cursor-pointer
            hover:text-on-primary
            hover:bg-primary-alt-500
            ${
                selected
                    ? 'bg-primary-alt-500 text-on-primary'
                    : 'text-on-background'
            }
            rounded-md
            flex items-center
            ${className}`}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        data-term={term}
    >
        {selected ? (
            <Check className="w-4 h-4 mr-2" />
        ) : (
            <div className="w-4 h-4 mr-2" />
        )}
        {children}
    </li>
)

interface SearchBoxProps {
    className?: string
}

const SearchBox = ({ className = '' }: SearchBoxProps) => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const [dropdown, setDropdown] = useState(false)
    const dropdownRef = useRef<HTMLDListElement>(null)

    const [selected, setSelect] = useState<SEARCH_TERMS>(
        SEARCH_TERMS.BLOCK_HEIGHT
    )
    const [inputText, setInputText] = useState('')
    const formClassName = useMemo(
        () =>
            new ClassHelper(className)
                .include(
                    `p-px rounded-md box-content
                    bg-gradient-to-r from-primary-alt-500 to-primary-500
                    flex`
                )
                .fallback('h-\\d+', 'h-10')
                .fallback('md:h-\\d+', 'md:h-14')
                .toString(),
        [className]
    )

    useEffect(() => {
        const clickOutside = (event: MouseEvent) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target as Node)
            ) {
                setDropdown(false)
            }
        }
        window.addEventListener('click', clickOutside)

        return () => {
            window.removeEventListener('click', clickOutside)
        }
    }, [])

    const onSearch = useCallback(
        (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault()

            switch (selected) {
                case SEARCH_TERMS.BLOCK_HEIGHT:
                case SEARCH_TERMS.BLOCK_HASH:
                    navigate(`${PATH.BLOCK}/${inputText}`)
                    break

                case SEARCH_TERMS.ADDRESS:
                    navigate(`${PATH.ACCOUNT}/${inputText}`)
                    break

                case SEARCH_TERMS.TX_ID:
                    navigate(`${PATH.TRANSACTION}/${inputText}`)
                    break
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [inputText, selected]
    )

    return (
        <form className={formClassName} onSubmit={onSearch}>
            <dl className="flex-none relative" ref={dropdownRef}>
                <dt
                    className="
                        h-full pl-3 pr-1 md:px-3
                        font-semibold text-xs md:text-sm
                        bg-background rounded-l-md
                        border-r border-transparent bg-clip-padding
                        flex items-center justify-between
                        cursor-pointer"
                    onClick={() => setDropdown(!dropdown)}
                >
                    {t(I18N_MAPPING_SEARCH_TERMS[selected])}
                    {dropdown ? (
                        <AngleUp className="w-8 h-8 pointer-events-none" />
                    ) : (
                        <AngleDown className="w-8 h-8 pointer-events-none" />
                    )}
                </dt>
                {dropdown && (
                    <dd
                        className="
                            min-w-max
                            absolute z-10
                            left-0 -bottom-1 translate-y-full
                            p-2
                            bg-surface rounded-md
                            border border-primary-alt-500"
                    >
                        <ul className="grid gap-y-0.5">
                            {Object.values(I18N_MAPPING_SEARCH_TERMS).map(
                                (i18nKey, index) => (
                                    <DropdownItem
                                        selected={index === selected}
                                        key={index}
                                        onMouseDown={() => setSelect(index)}
                                        onMouseUp={() => setDropdown(false)}
                                    >
                                        {t(i18nKey)}
                                    </DropdownItem>
                                )
                            )}
                        </ul>
                    </dd>
                )}
            </dl>
            <input
                type="text"
                placeholder="Search by block height, hash, transaction or address"
                className="
                    text-xs font-normal
                    placeholder:text-on-background-helper
                    flex-auto min-w-0 h-full
                    pl-4
                    bg-background
                    outline-none"
                value={inputText}
                onChange={(event) => setInputText(event.target.value)}
            />
            <button
                className="
                    md:text-2xl md:leading-6
                    px-3 md:px-4
                    rounded-r-md bg-background"
                type="submit"
            >
                <Search className="h-8" />
            </button>
        </form>
    )
}

export default SearchBox
