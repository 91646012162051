import React from 'react'

// table components
import {
    Cell as BasicCell,
    Row as BasicRow,
    Header as BasicHeader,
    Table as BasicTable
} from 'components/Table/core'
import { CellProps, RowProps, TableProps } from 'components/Table/core'
import { GradientText } from 'components/Text'

const Cell: React.FC<CellProps> = ({ className = '', ...restProps }) => (
    <BasicCell {...restProps} className={className} />
)

const HeaderCell: React.FC<CellProps> = ({
    children,
    className = '',
    ...restProps
}) => (
    <BasicCell {...restProps} className={className}>
        <GradientText>{children}</GradientText>
    </BasicCell>
)

const Row: React.FC<RowProps> = ({
    className = '',
    children,
    onClick,
    ...restProps
}) => (
    <BasicRow
        {...restProps}
        className={`
            text-base font-semibold
            md:h-20 md:pl-3
            bg-surface
            ${onClick ? 'hover:bg-background active:bg-surface' : ''}
            shadow-lg
            ${className}`}
        onClick={onClick}
    >
        {children}
    </BasicRow>
)

const Header: React.FC<RowProps> = ({
    className = '',
    children,
    ...restProps
}) => (
    <BasicHeader
        {...restProps}
        className={`
            text-xl font-semibold
            md:pl-3
            ${className}`}
    >
        {children}
    </BasicHeader>
)

const Table: React.FC<TableProps> = ({ className = '', ...restProps }) => (
    <BasicTable {...restProps} className={`text-center ${className}`} />
)

export { Cell, HeaderCell, Row, Header, Table }
