import { useNavigate } from 'react-router-dom'
// assets
import { ReactComponent as Logo } from 'images/BTQ-logo_on-dark.svg'

interface LogoBarProps {
    className?: string
}

const LogoBar = ({ className }: LogoBarProps) => {
    const naavigate = useNavigate()
    return (
        <div
            className={`w-fit flex items-end cursor-pointer ${className}`}
            onClick={() => naavigate('/')}
        >
            <Logo className="h-9 md:h-12 " />
            <span
                className="
                        -translate-y-1
                        hidden md:block
                        text-2xl font-semibold
                        ml-4"
            >
                Explorer
            </span>
        </div>
    )
}

export default LogoBar
