import { useTranslation } from 'react-i18next'
import { LANGUAGE_CODE } from 'constants/i18n'

// components
import LinkGroup from 'components/Footer/LinkGroup'
import Social from 'components/Footer/Social'

const Links = ({ className = '' }: { className?: string }) => {
    const { t, i18n } = useTranslation()

    const changeLanguage = (lang: string) => {
        i18n.changeLanguage(lang)
    }

    return (
        <div
            className={`
                min-w-max
                grid
                grid-cols-1 gap-5
                md:flex md:justify-between
                ${className}`}
        >
            <LinkGroup
                title={t('footer_links_company')}
                links={[
                    {
                        name: t('footer_links_our_team'),
                        href: 'https://www.btq.com/team'
                    },
                    {
                        name: t('footer_links_careers'),
                        href: 'https://www.btq.com/careers'
                    },
                    {
                        name: t('footer_links_blog'),
                        href: 'https://www.btq.com/blog'
                    }
                ]}
                className="md:text-left"
            />
            {/* <LinkGroup
                title={t('footer_links_documentation')}
                links={[{ name: 'Lightpaper', href: '#' }]}
            /> */}
            <LinkGroup
                title={t('footer_language')}
                links={[
                    {
                        name: 'English',
                        onclick: () => changeLanguage(LANGUAGE_CODE.EN)
                    },
                    {
                        name: '中文',
                        onclick: () => changeLanguage(LANGUAGE_CODE.ZH_TW)
                    }
                ]}
            />
            <Social title={t('footer_links_social')} />
        </div>
    )
}

export default Links
