// components
import { Row, Cell } from 'components/Table'
import {
    Filler,
    TextPlaceholder,
    HashPlaceholder
} from 'components/Decoration/Fillter'

// assets
import { ReactComponent as AngleRight } from 'images/icon-angle_right.svg'

const BlockListRrow = ({ className = '' }: { className?: string }) => (
    <Row className={className}>
        <Cell
            className="animate-pulse"
            width={'6rem'}
            desc={<Filler>Height</Filler>}
        >
            <TextPlaceholder className="w-full md:w-auto md:mx-2" />
        </Cell>
        <Cell
            className="animate-pulse"
            width={'32rem'}
            desc={<Filler>Block Hash</Filler>}
            shrink
        >
            <HashPlaceholder className="w-full md:w-auto md:mx-2" />
        </Cell>
        <Cell
            className="animate-pulse"
            width={'5rem'}
            desc={<Filler>TXS</Filler>}
        >
            <TextPlaceholder className="w-full md:w-auto md:mx-2" />
        </Cell>
        <Cell
            className="animate-pulse"
            width={'9rem'}
            desc={<Filler>Timestamp</Filler>}
        >
            <TextPlaceholder className="w-full md:w-auto md:mx-2" />
        </Cell>
        {/* <Cell
            className="animate-pulse"
            width={'7rem'}
            desc={<Filler>Size</Filler>}
        >
            <TextPlaceholder className="w-full md:w-auto md:mx-2" />
        </Cell> */}
        <Cell width="2rem" className="hidden md:flex justify-center">
            <AngleRight className="h-6" preserveAspectRatio="xMidYMid slice" />
        </Cell>
    </Row>
)

export { BlockListRrow }
