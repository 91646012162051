import {
    Filler,
    TextPlaceholder,
    HashPlaceholder
} from 'components/Decoration/Fillter'
import { TxInfoItem } from './Transaction'

// assets
import { ReactComponent as AngleRight } from 'images/icon-angle_right.svg'

const TxAmountHeaderLoading = ({
    role,
    className = ''
}: {
    role: string
    className?: string
}) => (
    <div
        className={`
            hidden md:flex
            text-xl font-semibold
            ${className}`}
    >
        <div className="flex-1 flex justify-start">
            <Filler>{role}</Filler>
        </div>
        <div className="w-24 flex justify-center">
            <Filler>Amount</Filler>
        </div>
    </div>
)

const TxAmountRowLoading = ({ className = '' }: { className?: string }) => (
    <div className={`contents md:flex md:flex-row ${className}`}>
        <TxInfoItem
            className="md:flex-1"
            descClassName="md:hidden"
            desc={<TextPlaceholder className="w-full" />}
            content={<TextPlaceholder className="w-full" />}
        />
        <TxInfoItem
            className="md:w-24 md:flex-none md:ml-5"
            descClassName="md:hidden"
            desc={<TextPlaceholder className="w-full" />}
            content={<TextPlaceholder className="w-full" />}
        />
    </div>
)

const SendRecvLoading = ({ className = '' }: { className?: string }) => (
    <div className={`contents md:flex ${className}`}>
        {/* sender */}
        <div className="flex-1 flex flex-col space-y-3">
            <TxAmountHeaderLoading role="Sender" />
            <TxAmountRowLoading />
        </div>
        {/* arrow */}
        <div className="flex-none h-7 w-16"></div>
        {/* receiver */}
        <div className="flex-1 flex flex-col space-y-3">
            <TxAmountHeaderLoading role="Receiver" />
            <TxAmountRowLoading />
        </div>
    </div>
)

const TxCardLoading = ({ className = '' }: { className?: string }) => (
    <div
        className={`
                w-full relative
                py-6 pl-5 pr-10
                bg-surface rounded-md shadow-lg
                hover:bg-background active:bg-surface
                cursor-pointer ${className}`}
    >
        <div className="w-full grid gap-y-2 md:gap-y-4 animate-pulse">
            <TxInfoItem
                desc={<Filler>Transaction ID</Filler>}
                content={<HashPlaceholder className="md:w-1/2" />}
                contentClassName="md:mt-3"
            />
            <SendRecvLoading className="w-full" />
        </div>
        <AngleRight className="absolute w-6 h-6 right-2 bottom-1/2 translate-y-1/2" />
    </div>
)

export { SendRecvLoading, TxCardLoading }
