import React from 'react'
import { Link } from 'react-router-dom'
import { PATH } from 'constants/url'
import { useTranslation } from 'react-i18next'
import { useMatch, useResolvedPath } from 'react-router-dom'

interface NavLinkProps {
    to: string
}

const NavLink: React.FC<NavLinkProps> = ({ to, children }) => {
    let resolved = useResolvedPath(to)
    let match = useMatch({ path: resolved.pathname, end: true })

    return (
        <div
            className={`
                ${match ? 'text-primary-500' : 'text-white'}
                hover:text-primary-500
                w-max h-full max-h-11
                border-b-2
                ${match ? 'border-primary-500' : 'border-transparent'}
                active:border-primary-700
                flex`}
        >
            <Link
                to={to}
                className="
                    text-lg font-semibold
                    text-center my-auto"
            >
                {children}
            </Link>
        </div>
    )
}

interface NavItemsProps {
    className?: string
}

const NavItems = ({ className = '' }: NavItemsProps) => {
    const { t } = useTranslation()

    return (
        <nav
            className={`
                min-w-fit grow
                grid grid-flow-col auto-cols-fr grid-rows-1
                items-center justify-items-center
                ${className}`}
        >
            <NavLink to={PATH.MAIN}>{t('general_navbar_dashboard')}</NavLink>
            <NavLink to={PATH.BLOCK_LIST}>{t('general_navbar_blocks')}</NavLink>
            <NavLink to={PATH.TRANSACTION_LIST}>
                {t('general_navbar_transactions')}
            </NavLink>
        </nav>
    )
}

export default NavItems
