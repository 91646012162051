import React from 'react'

// components
import { BasicInfoItemDesc, BasicInfoItem } from 'components/Card/core'
import { CopyIcon } from 'components/Decoration'

interface InfoItemProps {
    desc: string | React.ReactNode
    content: string | React.ReactNode
    showCopyIcon?: boolean
    className?: string
    onClick?: () => void
}

const InfoItem = ({ className = '', ...props }: InfoItemProps) => (
    <BasicInfoItem
        {...props}
        className={`
            font-semibold text-left
            text-xs md:text-xl
            ${className}`}
        contentClassName="md:mt-2"
    />
)

const LargeInfoItem = ({
    desc,
    content,
    showCopyIcon = false,
    className = ''
}: InfoItemProps) => (
    <div
        className={`
            row-span-full
            flex flex-col
            font-semibold
            ${className}`}
    >
        {/* desc part: desktop & mobile */}
        <BasicInfoItemDesc
            desc={desc}
            className="
                text-xs md:text-xl"
        />
        {/* content part */}
        {showCopyIcon ? (
            <CopyIcon
                className="flex-1 text-base mt-1 md:mt-3.5"
                textToCopy={typeof content === 'string' ? content : ''}
                iconPos="bottom-right"
                size="lg"
            >
                <div
                    className="
                        text-sm
                        flex-1 md:basis-0
                        break-all overflow-auto"
                >
                    {content}
                </div>
            </CopyIcon>
        ) : (
            <div
                className="
                    text-sm
                    mt-3.5
                    flex-1 basis-0
                    break-all overflow-auto"
            >
                {content}
            </div>
        )}
    </div>
)

const PanelCol: React.FC<{ className?: string }> = ({
    children,
    className = ''
}) => (
    <div
        className={`
            grid
            gap-y-3.5 md:gap-y-5
            ${className}`}
    >
        {children}
    </div>
)

const PanelRow: React.FC<{ className?: string }> = ({
    children,
    className = ''
}) => (
    // Desktop: grid, horizental, fr
    // Mobile: grid, vertical, min
    <div
        className={`
            grid
            grid-flow-row md:grid-flow-col
            auto-rows-min auto-cols-fr
            gap-y-3.5 md:gap-y-0
            gap-x-0 md:gap-x-6
            ${className}
    `}
    >
        {children}
    </div>
)

const PanelHr = () => (
    <div className="my-3.5 md:my-9 border-b border-b-on-surface-helper"></div>
)

interface PanelProps {
    className?: string
    onClick?: () => void
}

const Panel: React.FC<PanelProps> = ({ children, onClick, className = '' }) => (
    <div
        className={`
            py-6 px-3.5
            md:pt-7 md:pb-8 md:px-10
            bg-surface rounded-md shadow-lg
            ${className}`}
        onClick={onClick}
    >
        {children}
    </div>
)

export { InfoItem, LargeInfoItem, PanelCol, PanelRow, PanelHr, Panel }
