import { useTranslation } from 'react-i18next'

const Copyright = ({ className = '' }: { className: string }) => {
    const { t } = useTranslation()
    return (
        <div
            className={`
                text-xs font-semibold
                text-on-surface-helper
                flex flex-col items-center
                md:flex-row md:justify-end
                ${className}`}
        >
            <span>{t('footer_copyright')}</span>
            <span>
                <a href="/">{t('footer_terms_n_conditions')}</a> |{' '}
                <a href="/">{t('footer_privacy_policy')}</a>
            </span>
        </div>
    )
}

export default Copyright
