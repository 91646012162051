import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { title as t, description as d } from 'constants/metaData'
import useMetadata from 'components/Helmet/useMetadata'

interface HelmetProps {
    title?: string
    description?: string
    metaData?: Array<[string, string]> // TODO: add support (useMetadata)
}

const ExplorerHelmet = ({ title = t, description = d }: HelmetProps) => {
    const { i18n } = useTranslation()
    const meta = useMetadata(title, description)

    return (
        <Helmet
            htmlAttributes={{ lang: i18n.language }}
            title={title}
            titleTemplate="%s | BTQ"
        >
            <meta name="description" content={description} />
            {meta.map(({ property, content }) => (
                <meta key={property} property={property} content={content} />
            ))}
        </Helmet>
    )
}

export default ExplorerHelmet
