import { useCallback, useEffect, useState } from 'react'

import { getLatestTransactions, getLatestBlocks, getCandidateBlock } from 'api'

import NetworkStatCache from './networkStatsCache'

const networkStat = new NetworkStatCache()

export const useNetworkStats = () => {
    const [networkStats, setNetworkStats] = useState<{
        totalBlocks?: number
        totalTransactions?: number
    }>({
        totalBlocks: undefined,
        totalTransactions: undefined
    })
    useEffect(() => {
        networkStat.subscribe(setNetworkStats)
        return () => {
            networkStat.unsubscribe()
        }
    }, [])

    return networkStats
}

const useDashboardDataLoader = <T>(
    dataLoader: () => Promise<T>,
    onDataLoaded?: (data: T) => void,
    updateInterval = 10000 // 10 sec
): {
    data: T | undefined
    isLoading: boolean
    hasError: boolean
    fetch: () => void
} => {
    const [data, setData] = useState<T>()
    const [isLoading, setLoadingStatus] = useState<boolean>(true)
    const [hasError, setErrorStatus] = useState<boolean>(false)

    const fetch = useCallback(() => {
        setLoadingStatus(true)
        setErrorStatus(false)

        dataLoader()
            .then((data) => {
                setData(data)
                setLoadingStatus(false)
                if (onDataLoaded) {
                    onDataLoaded(data)
                }
            })
            .catch((err) => {
                console.error('ERROR: Cannot fetch data', err)
                setErrorStatus(true)
                setLoadingStatus(false)
            })
    }, [dataLoader, onDataLoaded])

    useEffect(() => {
        let timeoutId: ReturnType<typeof setTimeout>
        const startFetch = async () => {
            await fetch()
            timeoutId = setTimeout(startFetch, updateInterval)
        }

        startFetch()
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []) // For now we only need to trigger in once

    return {
        data,
        isLoading,
        hasError,
        fetch
    }
}

export const useLatestTransaction = (txCount: number) => {
    const { data, isLoading, hasError, fetch } = useDashboardDataLoader(
        () => getLatestTransactions(txCount),
        (data) => networkStat.updateTotalTransactions(data?.total)
        // 1000 // 10 sec (default)
    )

    return {
        transactions: data?.result,
        isLoading: isLoading,
        hasError: hasError,
        update: fetch
    }
}

export const useLatestFinalizedBlock = (blockCount: number) => {
    const ret = useDashboardDataLoader(
        () => getLatestBlocks(blockCount),
        (data) => {
            networkStat.updateTotalBlock(data?.total)
        },
        2 ** 31 - 1 // max delay
    )

    return {
        blocks: ret.data?.result,
        isLoading: ret.isLoading,
        hasError: ret.hasError,
        update: ret.fetch
    }
}

export const useCandidateBlock = () => {
    const ret = useDashboardDataLoader(
        () => getCandidateBlock(),
        () => {},
        30000 // 3sec
    )

    return {
        block: ret.data,
        isLoading: ret.isLoading,
        hasError: ret.hasError,
        update: ret.fetch
    }
}
