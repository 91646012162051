type Subscriber = (payload: {
    totalBlocks?: number
    totalTransactions?: number
}) => void

/**
 * If we decide to use RxJs or MobX, the following implementation can be a lot simpler.
 * TODO: Replace this implementation if we are using RxJs or MobX
 */
export default class NetworkStatsCache {
    private totalBlocks?: number
    private totalTransactions?: number

    private subscriber?: Subscriber

    public updateTotalBlock(count?: number) {
        this.totalBlocks = count
        this.notify()
    }
    public updateTotalTransactions(count?: number) {
        this.totalTransactions = count
        this.notify()
    }
    public notify() {
        if (this.subscriber) {
            this.subscriber({
                totalBlocks: this.totalBlocks,
                totalTransactions: this.totalTransactions
            })
        }
    }

    public subscribe(subscriber: Subscriber) {
        this.subscriber = subscriber
        this.notify()
    }
    public unsubscribe() {
        this.subscriber = undefined
    }
}
