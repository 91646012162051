import React from 'react'

// components
import { Icon } from 'components/Card/IconTitle'
import { ElipseHashDisplay } from 'components/display/Hash'

type TypeInfoItem = {
    desc: string
    content?: string | React.ReactNode
    className?: string
}

const InfoItem = ({ desc, content, className = '' }: TypeInfoItem) => (
    <div
        className={`
            flex md:flex-col
            items-center md:items-start
            ${className}`}
    >
        <span
            className="
                text-base md:text-4xl font-bold
                w-20 md:w-auto flex-none"
        >
            {desc}
        </span>
        {content !== undefined && (
            <span
                className="
                    text-xs md:text-sm font-semibold
                    min-w-0 max-w-full flex-1"
            >
                {content}
            </span>
        )}
    </div>
)

const Divider = () => (
    <>
        {/* vertical divider */}
        <div
            className="
                hidden md:block mx-7
                w-0 flex-none
                border-r border-on-background-helper"
        ></div>
        {/* horizental divider */}
        <div
            className="
                block md:hidden my-1
                w-full h-0
                border-b border-on-background-helper"
        ></div>
    </>
)

interface IconTitleMoreProps {
    icon: string | React.FunctionComponent<React.SVGProps<SVGSVGElement>>
    iconAlt?: string
    title: string
    hash?: string
    infoItems?: TypeInfoItem | TypeInfoItem[]
    className?: string
}

// TODO: All pages should use the same IconTitle implementation
const IconTitleMore = ({
    icon,
    iconAlt,
    title,
    hash,
    infoItems = [],
    className = ''
}: IconTitleMoreProps) => {
    const InfoItemList = Array.isArray(infoItems) ? infoItems : [infoItems]

    return (
        <div className={`flex items-center ${className}`}>
            <Icon
                className="flex-none"
                icon={icon}
                iconAlt={iconAlt || title}
            />
            <div
                className="
                    flex-1 min-w-0
                    flex flex-col md:flex-row items-stretch
                    ml-2 md:ml-4"
            >
                <InfoItem
                    className="
                        flex-1 min-w-0"
                    desc={title}
                    content={
                        hash ? (
                            <ElipseHashDisplay value={hash} showCopyIcon />
                        ) : undefined
                    }
                />
                {InfoItemList.map((infoItem) => (
                    <React.Fragment key={infoItem.desc}>
                        <Divider />
                        <InfoItem
                            className="flex-1 min-w-0 max-w-max"
                            {...infoItem}
                        />
                    </React.Fragment>
                ))}
            </div>
        </div>
    )
}

export default IconTitleMore
export { Divider }
