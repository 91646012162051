import React, { useEffect, useState } from 'react'

interface TimestampDisplayProps {
    value: Date | number // unix time
}

enum timeBase {
    yr = 31536000,
    mo = 2592000,
    day = 86400,
    h = 3600,
    m = 60,
    s = 1
}

const TimestampDisplay = ({ value }: TimestampDisplayProps) => {
    const time: Date = typeof value === 'number' ? new Date(value) : value

    return <>{readablizeAgo(time).map(agoFormater)} ago</>
}

// ToDo:
// We might need a dynamic re-fresh rate:
// under 1 minute, refresh rate will be 1 second, and display will be: "45 seconds ago"
// Between 1 minute ~ 1 hours, refresh rate will be 15 seconds, and the display will be: "3 minutes ago"
// between 1 hours ~ 1 day, refresh rate will be 15 minutes, and the display will be: '3 hours ago'
// Beyond 1 day, there will be no re-fresh, and the display will be: '3 days ago', '4 months ago', 'two years ago'

const LiveTimestampDisplay = ({ value }: TimestampDisplayProps) => {
    const time: Date = typeof value === 'number' ? new Date(value) : value

    const [readale, setReadable] = useState(readablizeAgo(time))
    const updateInterval =
        readale.length > 1 ? readale[readale.length - 1]?.[1] : 's'

    useEffect(() => {
        const timerId = setInterval(() => {
            setReadable(readablizeAgo(time))
        }, timeBase[updateInterval as keyof typeof timeBase] * 1000)
        return () => {
            clearInterval(timerId)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value])

    return readale.length > 0 ? <>{readale.map(agoFormater)} ago</> : <>now</>
}

export { TimestampDisplay, LiveTimestampDisplay }

// return [[time, base]...] e.g. [[1, 'yr'], [2, 'mo']]
function readablizeAgo(time: Date): Array<[number, string]> {
    if (!time) {
        return []
    }

    const seconds = Math.floor((Date.now() - time.valueOf()) / 1000)
    const result: [number, keyof typeof timeBase][] = new Array<
        [number, keyof typeof timeBase]
    >()

    function pushIfBigger(seconds: number, base: timeBase): number {
        if (seconds >= base) {
            const interval = Math.floor(seconds / base)
            result.push([interval, timeBase[base] as keyof typeof timeBase])
        }
        return seconds % base
    }

    let renainder: number
    renainder = pushIfBigger(seconds, timeBase.yr)
    renainder = pushIfBigger(renainder, timeBase.mo)
    renainder = pushIfBigger(renainder, timeBase.day)
    renainder = pushIfBigger(renainder, timeBase.h)
    renainder = pushIfBigger(renainder, timeBase.m)
    pushIfBigger(renainder, timeBase.s)

    return result.slice(0, 2)
}

function agoFormater([value, base]: [number, string]) {
    return (
        <React.Fragment key={base}>
            {value}
            <span style={{ fontSize: '0.75em' }}>{base}</span>
        </React.Fragment>
    )
}
