/**
 * We connect to API server directly for now, which requires CORS to be enabled.
 * For production, we may consider to use Netlify's proxy features so that we don't need to worry about choosing the correct endpoint.
 * Which also implies that the URL for our API gateway won't be exposed to the public
 */
enum API_ENDPOINTS {
    PRODUCTION = '',
    STAGING = '',
    TEST = 'https://d1b58bcnhhs125.cloudfront.net/'
    // TEST = 'http://localhost:3333'
}

export const getApiEndPoint = (): string => {
    return API_ENDPOINTS.TEST
}
