import { useEffect, useState } from 'react'

import { getTransactionDetail } from 'api'
import { TransactionDetail } from 'api/types'

export const useTransactionDetail = (
    hash?: string
): {
    isLoading: boolean
    hasError: boolean
    transactionDetail?: TransactionDetail
} => {
    const [result, setResult] = useState<TransactionDetail>()
    const [isLoading, setLoadingStatus] = useState(true)
    const [hasError, setErrorStatus] = useState(false)

    useEffect(() => {
        if (typeof hash !== 'string') {
            setErrorStatus(true)
            setResult(undefined)
            setLoadingStatus(false)
            return
        }

        setLoadingStatus(true)
        setErrorStatus(false)

        getTransactionDetail(hash)
            .then((data) => {
                setResult(data)
                setLoadingStatus(false)
            })
            .catch((err) => {
                console.error(
                    `ERROR: Unable to get transaction detail for hash ${hash}`,
                    err
                )
                setErrorStatus(true)
                setLoadingStatus(false)
            })
    }, [hash])

    return {
        transactionDetail: result,
        isLoading,
        hasError
    }
}
