// components
import Section from 'components/layout/Section'
import CompanyInfo from 'components/Footer/CompanyInfo'
import Links from 'components/Footer/Links'
import Copyright from 'components/Footer/Copyright'

interface HasClassName {
    className?: string
}

const Divider = ({ className = '' }: HasClassName) => (
    <div
        className={`
        w-20 h-px
        border border-primary-500 ${className}`}
    ></div>
)

const Footer = ({ className = '' }: HasClassName) => (
    <footer
        className={`
            w-full pb-2.5 md:pb-10
            bg-background
            ${className}`}
    >
        <Section className="mt-3.5 md:mt-20">
            <div
                className="
                    flex
                    flex-col md:flex-row-reverse md:justify-between"
            >
                <Links className="md:ml-1 md:max-w-xl md:flex-auto" />
                <Divider className="mt-9 md:hidden" />
                <CompanyInfo className="mt-5 md:mt-0 md:max-w-sm md:flex-auto" />
            </div>
            <Copyright className="mt-14 md:mt-6" />
        </Section>
    </footer>
)

export default Footer
