import { BlockInfoLoading } from './BlockInfo'
import { TxBlockLoading } from './Transaction'

const AggSignatureLoading = ({ className = '' }: { className?: string }) => (
    <div className={`w-full flex ${className}`}>
        <TxBlockLoading className="w-3/5 min-w-0" />
        <BlockInfoLoading className="w-2/5 min-w-0 ml-5" />
    </div>
)

export default AggSignatureLoading
