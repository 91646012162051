import React from 'react'

interface SectionProps {
    className?: string
}

const Section: React.FC<SectionProps> = ({ className = '', children }) => (
    <section
        className={`
            w-full
            px-4 md:px-8
            flex justify-center ${className}`}
    >
        <div
            className="
                w-full md:max-w-screen-lg
                flex flex-col"
        >
            {children}
        </div>
    </section>
)

export default Section
