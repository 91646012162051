import React from 'react'

// components
import { InfoItem, PanelCol, Panel } from 'components/Card/Panel'

interface CellProps {
    width?: string // initial width
    shrink?: boolean
    className?: string
    desc?: string | React.ReactNode
}

const Cell: React.FC<CellProps> = ({
    width,
    shrink = false,
    className = '',
    children,
    desc = ''
}) => (
    <>
        {/* normal cell */}
        <div
            className={`
                hidden md:block
                p-2
                min-w-0
                ${className}`}
            style={{
                flex: `1 ${shrink ? 1 : 0} ${width}`
            }}
        >
            {children}
        </div>

        {/*  info card */}
        <InfoItem
            className={`block md:hidden ${className}`}
            desc={desc}
            content={children}
        />
    </>
)

interface RowProps {
    className?: string
    onClick?: () => void
}

const Row: React.FC<RowProps> = ({ className = '', children, onClick }) => (
    <>
        {/* normal row */}
        <div
            className={`
                w-full
                rounded-md
                hidden md:flex
                flex-row items-center
                ${onClick ? 'cursor-pointer' : ''}
                ${className}`}
            onClick={onClick}
        >
            {children}
        </div>
        {/*  info card */}
        <Panel className={`md:hidden ${className}`} onClick={onClick}>
            <PanelCol>{children}</PanelCol>
        </Panel>
    </>
)

const Header: React.FC<RowProps> = ({
    className = '',
    children,
    ...restProps
}) => (
    <Row
        {...restProps}
        className={`
            hidden md:flex
            ${className}`}
    >
        {children}
    </Row>
)

interface TableProps {
    className?: string
}

const Table: React.FC<TableProps> = ({ className = '', children }) => (
    <div
        className={`
            w-full
            grid auto-cols-fr
            gap-y-2.5 md:gap-y-4
            ${className}`}
    >
        {children}
    </div>
)

export { Cell, Row, Header, Table }
export type { CellProps, RowProps, TableProps }
