import { useEffect, useState } from 'react'

import { getBlockDetail } from 'api'
import { BlockDetail } from 'api/types'

export const useBlockDetail = (
    hash?: string
): {
    isLoading: boolean
    hasError: boolean
    blockDetail?: BlockDetail
} => {
    const [isLoading, setLoadingStatus] = useState(true)
    const [hasError, setErrorStatus] = useState(false)
    const [result, setResult] = useState<BlockDetail>()

    useEffect(() => {
        if (typeof hash !== 'string') {
            setErrorStatus(true)
            setResult(undefined)
            setLoadingStatus(false)
            return
        }

        setLoadingStatus(true)
        setErrorStatus(false)

        getBlockDetail(hash)
            .then((data) => {
                setResult(data)
                setLoadingStatus(false)
            })
            .catch((err) => {
                console.error(
                    `ERROR: Unable to get block detail for hash ${hash}`,
                    err
                )
                setErrorStatus(true)
                setLoadingStatus(false)
            })
    }, [hash])

    return {
        blockDetail: result,
        isLoading,
        hasError
    }
}
