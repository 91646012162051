import {
    MAX_BLOCK_COUNT,
    TX_PER_BLOCK,
    RECEIVER_ADDRESS,
    TX_VALUES,
    BLOCK_DEFAULT_DETAIL
} from './constants'
import {
    BlockDetailApiPayload,
    TransactionDetailApiPayload,
    AccountStatPayload,
    AccountTransactionItem
} from 'api/types'
import { numberToHexStr, generateRandomHexString, getBlockTime } from './utils'

const BLOCK_NUMBER_TO_HASH: { [blockNum: number]: string } = {}
export const BLOCK_HASH_TO_NUMBER: { [hash: string]: number } = {}
const BLOCK_NUMBER_TO_SIGNATURE: { [blockNum: number]: string } = {}
for (let i = 0; i < MAX_BLOCK_COUNT; i++) {
    const hash = generateRandomHexString(64)
    BLOCK_NUMBER_TO_HASH[i] = hash
    BLOCK_HASH_TO_NUMBER[hash] = i
    BLOCK_NUMBER_TO_SIGNATURE[i] = generateRandomHexString(182 * 1024)
}

const publicKeyPool = [...Array(16)].map(() => generateRandomHexString(1795))
const signaturePool = [...Array(32)].map(() => generateRandomHexString(1236))

const createMockTx = (
    blockNumber: number,
    txIndex: number
): TransactionDetailApiPayload => {
    const blockTime = getBlockTime(blockNumber)

    return {
        id: -1,
        hash: generateRandomHexString(64),
        nonce: '0x0',
        blockHash: BLOCK_NUMBER_TO_HASH[blockNumber],
        transactionIndex: numberToHexStr(txIndex),
        from: generateRandomHexString(40),
        to: RECEIVER_ADDRESS,
        value: TX_VALUES[Math.floor(txIndex % 3)],
        gas: '0x5208',
        gasPrice: '0x8',
        input: '0x',
        v: '0x1df',
        r: publicKeyPool[Math.floor(txIndex % 16)],
        s: signaturePool[Math.floor(txIndex % 32)],
        block: {
            number: blockNumber,
            timestamp: blockTime
        },
        signature: '',
        created_at: blockTime
    }
}

export const fakeTxData = new Map<string, TransactionDetailApiPayload>()
const mapFakeAddressToTx = new Map<string, string>()
export const mockBlockTxs = new Map<
    number,
    BlockDetailApiPayload['transactions']
>()

for (let blockNum = 0; blockNum < MAX_BLOCK_COUNT; blockNum++) {
    const blockTxs: BlockDetailApiPayload['transactions'] = []

    for (let i = 0; i < TX_PER_BLOCK; i++) {
        const mockTx = createMockTx(blockNum, i)
        fakeTxData.set(mockTx.hash, mockTx)
        mapFakeAddressToTx.set(mockTx.from, mockTx.hash)
        blockTxs.push({
            hash: mockTx.hash,
            from: mockTx.from,
            to: mockTx.to,
            value: mockTx.value
        })
    }
    mockBlockTxs.set(blockNum, blockTxs)
}

export const getMockBlockDetail = (
    blockNumber: number
): BlockDetailApiPayload => {
    const aggSigDetail =
        blockNumber < MAX_BLOCK_COUNT - 1
            ? {
                  signature: BLOCK_NUMBER_TO_SIGNATURE[blockNumber],
                  minerBlockNumber: blockNumber + 1
              }
            : null

    return {
        ...BLOCK_DEFAULT_DETAIL,
        hash: BLOCK_NUMBER_TO_HASH[blockNumber],
        number: blockNumber,
        transactions: mockBlockTxs.get(blockNumber) || [],
        myAggregateSignatureDetail: aggSigDetail,
        timestamp: getBlockTime(blockNumber)
    }
}

export const mockAccountTx = (
    id: string,
    count: number,
    offset: number
): [AccountTransactionItem[] | undefined, number] => {
    if (id === RECEIVER_ADDRESS) {
        const tx = Array.from(fakeTxData.values()).map((tx) => ({
            id: -1,
            hash: tx.hash,
            block: {
                height: tx.block.number,
                hash: tx.blockHash
            },
            created_at: '',
            from: tx.from,
            to: tx.to,
            value: tx.value,
            timestamp: new Date()
        }))
        return [tx.slice(offset, offset + count), tx.length]
    }

    const addressTxHash = mapFakeAddressToTx.get(id)
    if (addressTxHash) {
        const tx = fakeTxData.get(addressTxHash)
        if (tx) {
            return [
                [
                    {
                        id: -1,
                        hash: tx.hash,
                        block: {
                            height: tx.block.number,
                            hash: tx.blockHash
                        },
                        created_at: '',
                        from: tx.from,
                        to: tx.to,
                        value: tx.value,
                        timestamp: new Date()
                    }
                ],
                1
            ]
        }
    }

    return [undefined, 0]
}

export const mockAccountStat = (id: string): AccountStatPayload | undefined => {
    if (id === RECEIVER_ADDRESS) {
        return {
            nonce: '0x0',
            balance: '0xe95fe06321e8a400000'
        }
    }

    const addressTx = mapFakeAddressToTx.get(id)
    return addressTx
        ? {
              nonce: '0x0',
              balance: '0x0'
          }
        : undefined
}
