import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { PATH } from 'constants/url'

// components
import Section from 'components/layout/Section'
import Helmet from 'components/Helmet'
import IconTitle from 'components/Card/IconTitle'
import {
    TxCard,
    TxCardLoading,
    TxCardLayout
} from 'components/Card/Transaction'
import { BlockInfoPanel } from './Panel'
import AggSignature from 'components/Visual/AggSignature'
import Pagination from 'components/Pagination'
import { BouncingEllipses } from 'components/Decoration'

// assets
import { ReactComponent as Cube } from 'images/circled-cube.svg'
import { ReactComponent as SpinArrow } from 'images/circled-spin_arrow.svg'

// api
import { useBlockDetail } from './hooks'

const ITEM_PER_PAGE = 5

const BlockInfoPage = () => {
    const params = useParams()
    const { t } = useTranslation()
    const navigate = useNavigate()

    const [currentItem, setItem] = useState(0)
    const {
        // TODO: handle loading/error status
        isLoading,
        hasError,
        blockDetail
    } = useBlockDetail(params.hashOrHeight)

    // redirect if not exist
    useEffect(() => {
        if (!isLoading && !hasError && !blockDetail) {
            navigate(PATH.NOT_FOUND, { replace: true })
        }
    }, [blockDetail, hasError, isLoading, navigate])

    return (
        <>
            {/* TODO: deal with blockId=undefined */}
            <Helmet
                title={t('blockinfo_meta_title', {
                    blockId: blockDetail?.number
                })}
                description={t('blockinfo_meta_description', {
                    blockId: blockDetail?.hash
                })}
            />

            <Section className="mt-5 md:mt-7">
                {blockDetail?.myAggregateSignatureDetail?.signature && (
                    <AggSignature
                        className="hidden md:flex mb-12"
                        blockInfo={blockDetail}
                    />
                )}

                <IconTitle
                    icon={Cube}
                    title={t('blockinfo_title', {
                        blockId: blockDetail?.number || ''
                    })}
                    desc={
                        blockDetail?.hash ?? (
                            <>
                                {isLoading && <BouncingEllipses />}
                                {!isLoading && !blockDetail?.hash && (
                                    <BouncingEllipses />
                                )}
                            </>
                        )
                    }
                />
                <BlockInfoPanel
                    className="mt-4"
                    blockDetail={blockDetail}
                    isLoading={isLoading}
                />
            </Section>

            <Section className="mt-5 md:mt-14">
                <IconTitle
                    icon={SpinArrow}
                    title={t('blockinfo_tx_title', {
                        txCnt: blockDetail?.transactions?.length || ''
                    })}
                />
                {/* ToDo: add Verified icon */}
                <TxCardLayout className="mt-4 md:mt-6">
                    {isLoading
                        ? Array(ITEM_PER_PAGE)
                              .fill(0)
                              .map((_, idx) => <TxCardLoading key={idx} />)
                        : blockDetail?.transactions
                              .slice(currentItem, currentItem + ITEM_PER_PAGE)
                              .map((tx) => (
                                  <TxCard
                                      key={tx.hash}
                                      hash={tx.hash}
                                      from={[
                                          { account: tx.from, amount: tx.value }
                                      ]}
                                      to={[
                                          { account: tx.to, amount: tx.value }
                                      ]}
                                      verified={true}
                                  />
                              ))}
                </TxCardLayout>

                <div className="mt-12 md:mt-6 px-1.5 flex flex-row-reverse">
                    <Pagination
                        totalItems={blockDetail?.transactions.length || 0}
                        itemsPerPage={ITEM_PER_PAGE}
                        currentItem={currentItem}
                        onPageSelected={(itemIndex) => setItem(itemIndex)}
                    />
                </div>
            </Section>
        </>
    )
}

export default BlockInfoPage
