import { useState, memo } from 'react'
import { useNavigate } from 'react-router-dom'

// components
import Pagination from 'components/Pagination'
import { Cell, HeaderCell, Row, Header, Table } from 'pages/AccountInfo/Table'
import { ElipseHashDisplay } from 'components/display/Hash'
import { CurrencyDisplay } from 'components/display/Currency'
import { ClickableAddress } from 'components/Card/Transaction'

// api
import { useAccountTransaction } from './hooks'
import { AccountTransactionItem } from 'api/types'

enum TxDirection {
    IN,
    OUT,
    NONE
}

const InOut = ({ dir }: { dir: TxDirection }) => (
    <div
        className={`
            text-xs
            px-1 py-1.5
            rounded-md 
            ${
                {
                    [TxDirection.IN]: 'bg-primary-alt-500 text-on-primary',
                    [TxDirection.OUT]: 'bg-primary-500 text-on-primary',
                    [TxDirection.NONE]:
                        'bg-on-surface-helper/30 text-transparent'
                }[dir]
            }`}
    >
        {TxDirection[dir]}
    </div>
)

interface TxRowProps {
    tx: AccountTransactionItem
    accountId?: string
    onClick?: () => void
    className?: string
}

const TxRowOK = ({ tx, accountId, onClick, className = '' }: TxRowProps) => (
    <Row key={tx.hash} className={className} onClick={onClick}>
        <Cell width={['14rem', '9rem']} shrink>
            <ElipseHashDisplay value={tx.hash} />
        </Cell>
        <Cell width={['6rem', '3rem']}>{tx.block.height}</Cell>

        <Cell width={['11rem', '8rem']} shrink>
            <ClickableAddress address={tx.from} />
        </Cell>
        <Cell width={['3.5rem', '3rem']}>
            <InOut
                dir={accountId === tx.from ? TxDirection.OUT : TxDirection.IN}
            />
        </Cell>
        {/*  OUT / IN */}
        <Cell width={['11rem', '8rem']} shrink>
            <ClickableAddress address={tx.to} />
        </Cell>
        <Cell width={['9rem', '7rem']}>
            <CurrencyDisplay wei={tx.value} />
        </Cell>
    </Row>
)

const TxRowLoading = ({ className = '' }: { className?: string }) => (
    <Row className={`animate-pulse ${className}`}>
        <Cell width={['14rem', '9rem']} shrink></Cell>
        <Cell width={['6rem', '3rem']}></Cell>
        <Cell width={['11rem', '8rem']} shrink></Cell>
        <Cell width={['3.5rem', '3rem']}>
            <InOut dir={TxDirection.NONE} />
        </Cell>
        {/*  OUT / IN */}
        <Cell width={['11rem', '8rem']} shrink></Cell>
        <Cell width={['9rem', '7rem']}></Cell>
    </Row>
)

interface TxListProps {
    accountId: string | undefined
    itemsPerPage?: number
    className?: string
}

const TxList = memo(function TxList({
    accountId,
    itemsPerPage = 10,
    className = ''
}: TxListProps) {
    const navigate = useNavigate()
    const [currentItem, setItem] = useState(0)
    const { txData, totalItem, isLoading } = useAccountTransaction(
        accountId,
        itemsPerPage,
        currentItem
    )

    return (
        <>
            <Table className={className}>
                <Header className="min-w-[44rem]">
                    <HeaderCell
                        width={['14rem', '9rem']}
                        shrink
                        className="text-left"
                    >
                        Teansaction ID
                    </HeaderCell>
                    <HeaderCell width={['6rem', '3rem']}>Block</HeaderCell>
                    <HeaderCell width={['11rem', '8rem']} shrink>
                        From
                    </HeaderCell>
                    <HeaderCell width={['3.5rem', '3rem']}></HeaderCell>{' '}
                    {/*  OUT / IN */}
                    <HeaderCell width={['11rem', '8rem']} shrink>
                        To
                    </HeaderCell>
                    <HeaderCell width={['9rem', '7rem']}>Amount</HeaderCell>
                </Header>

                {isLoading
                    ? Array(itemsPerPage)
                          .fill(0)
                          .map((_, idx) => <TxRowLoading key={idx} />)
                    : txData.map((tx) => (
                          <TxRowOK
                              key={tx.hash}
                              className="min-w-[44rem]"
                              onClick={() =>
                                  navigate(`/transactions/${tx.hash}`)
                              }
                              tx={tx}
                              accountId={accountId}
                          />
                      ))}
            </Table>
            <div className="mt-12 md:mt-6 px-1.5 flex flex-row-reverse">
                <Pagination
                    totalItems={totalItem}
                    itemsPerPage={itemsPerPage}
                    currentItem={currentItem}
                    onPageSelected={(itemIndex) => setItem(itemIndex)}
                />
            </div>
        </>
    )
})

export default TxList
