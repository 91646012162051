import { BigNumber, utils, BigNumberish } from 'ethers'

export const covertWeiToEther = (value: BigNumberish): string => {
    try {
        const wei = BigNumber.from(value)

        return utils.formatEther(wei).toString()
    } catch (e) {
        console.error(
            `Unable to covert ${value} to Ether string representation`
        )
        return ''
    }
}

export const displayHexInDecimal = (data?: string | null) => {
    try {
        return data ? `${parseInt(data, 16)}` : ''
    } catch (e) {
        return ''
    }
}
