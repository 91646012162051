import React, { Fragment } from 'react'

// components
import { CopyIcon, CopyIconSize } from 'components/Decoration'
import { CopyButton } from 'components/Decoration/CopyIcon'
import { ElipseableText } from 'components/Text'

type OverflowBehavior = 'elipse' | 'segment' | 'wrap'

interface BasicHashDisplayProps {
    value: string
    className?: string
    showCopyIcon?: boolean | CopyIconSize
}

const getIconSize = (showCopyIcon: boolean | CopyIconSize) => {
    return typeof showCopyIcon === 'boolean' ? undefined : showCopyIcon
}

const ElipseHashDisplay = ({
    value,
    className = '',
    showCopyIcon = false
}: BasicHashDisplayProps) =>
    showCopyIcon ? (
        <CopyIcon
            textToCopy={value}
            className={className}
            size={getIconSize(showCopyIcon)}
        >
            <ElipseableText value={value} />
        </CopyIcon>
    ) : (
        <ElipseableText value={value} className={className} />
    )

const SegmentHashDisplay = ({
    value,
    className = '',
    showCopyIcon = false
}: BasicHashDisplayProps) => {
    const breakWords = value.match(/.{1,33}/g) || ['']
    const content = (
        <span className="w-min">
            {breakWords.map((segment, idx) => (
                <Fragment key={idx}>
                    {segment}
                    <wbr />
                </Fragment>
            ))}
        </span>
    )
    return showCopyIcon ? (
        <CopyIcon
            textToCopy={value}
            className={className}
            iconPos="bottom-right"
            size={getIconSize(showCopyIcon)}
        >
            <div className="w-full flex items-center">{content}</div>
        </CopyIcon>
    ) : (
        <div className={`w-full flex items-center ${className}`}>{content}</div>
    )
}

const WrapHashDisplay = ({
    value,
    className = '',
    showCopyIcon = false
}: BasicHashDisplayProps) => (
    <span className={`break-all inline ${className}`}>
        {value}
        {showCopyIcon && (
            <CopyButton
                className="inline ml-1"
                textToCopy={value}
                size={getIconSize(showCopyIcon)}
            />
        )}
    </span>
)

const overflowMap = {
    elipse: ElipseHashDisplay,
    segment: SegmentHashDisplay,
    wrap: WrapHashDisplay
}

interface HashDisplayProps {
    value: string
    className?: string
    overflow?:
        | OverflowBehavior
        | { mobile: OverflowBehavior; desktop: OverflowBehavior }
    showCopyIcon?:
        | boolean
        | CopyIconSize
        | { mobile: boolean | CopyIconSize; desktop: boolean | CopyIconSize }
}

const HashDisplay = React.memo(function hashDisplay({
    value,
    className = '',
    overflow = 'elipse',
    showCopyIcon = false
}: HashDisplayProps) {
    // make sure the value start with 0x
    const OxValue = value?.startsWith('0x') ? value : `0x${value || ''}`

    // expand `overflow` & `showCopyIcon` options
    const overflowBehavior =
        typeof overflow === 'string'
            ? { mobile: overflow, desktop: overflow }
            : overflow
    const showCopyIconBehavior =
        typeof showCopyIcon !== 'object'
            ? { mobile: showCopyIcon, desktop: showCopyIcon }
            : showCopyIcon

    const [ForMobile, ForDesktop] = [
        overflowBehavior.mobile,
        overflowBehavior.desktop
    ].map((behavior) => overflowMap[behavior])

    return (
        <>
            {/* for Mobile screen */}
            <ForMobile
                value={OxValue}
                className={`text-xs md:hidden ${className}`}
                showCopyIcon={showCopyIconBehavior.mobile}
            />
            {/* for Desktop screen */}
            <ForDesktop
                value={OxValue}
                className={`text-sm hidden md:flex ${className}`}
                showCopyIcon={showCopyIconBehavior.desktop}
            />
        </>
    )
})

export { ElipseHashDisplay, SegmentHashDisplay, WrapHashDisplay, HashDisplay }
