import { Routes, Route } from 'react-router'
import { PATH } from 'constants/url'

// pages
import AccountInfoPage from 'pages/AccountInfo'
import BlockInfoPage from 'pages/BlockInfo'
import BlockListPage from 'pages/BlockList'
import DashboardPage from 'pages/Dashboard'
import TxInfoPage from 'pages/TxInfo'
import TxListPage from 'pages/TxList'
import NotFoundPage from 'pages/404'

// components
import Header from 'components/Header'
import Footer from 'components/Footer'

const App = () => (
    <div
        className="
            text-white selection:bg-primary-900
            min-h-screen
            bg-gradient-to-b from-gradient-start to-gradient-end
            flex flex-col"
    >
        <Header />
        <div className="flex-1 mb-12 md:mb-28">
            <Routes>
                <Route path={PATH.MAIN} element={<DashboardPage />} />
                <Route path={PATH.ACCOUNT} element={<AccountInfoPage />} />
                <Route
                    path={`${PATH.ACCOUNT}/:accountId`}
                    element={<AccountInfoPage />}
                />
                <Route path={PATH.BLOCK_LIST} element={<BlockListPage />} />

                <Route path={PATH.BLOCK} element={<BlockInfoPage />} />
                <Route
                    path={`${PATH.BLOCK}/:hashOrHeight`}
                    element={<BlockInfoPage />}
                />

                <Route path={PATH.TRANSACTION_LIST} element={<TxListPage />} />

                <Route path={PATH.TRANSACTION} element={<TxInfoPage />} />
                <Route
                    path={`${PATH.TRANSACTION}/:txHash`}
                    element={<TxInfoPage />}
                />

                {/* catch-all route */}
                <Route path="*" element={<NotFoundPage />} />
            </Routes>
        </div>
        <Footer />
    </div>
)

export default App
