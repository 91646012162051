import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// components
import { HashDisplay } from 'components/display/Hash'
// import { ByteSizeDisplay } from 'components/display/Byte'
// import { LiveTimestampDisplay } from 'components/display/Timestamp'
import { Cell, HeaderCell, Row, Header, Table } from 'components/Table'
import { CurrencyDisplay } from 'components/display/Currency'
import { LoadMoreButton } from 'components/Button'
import { TxListRow as TxListRowLoading } from './TxListLoading'

// assets
import { ReactComponent as AngleRight } from 'images/icon-angle_right.svg'

// api
import { useTransactionList } from './hooks'
import { TransactionListItem } from 'api/types'

const ITEMS_PER_FETCH = 20

interface TxListRrowProps {
    txItem: TransactionListItem
    onClick: () => void
    className?: string
}

const TxListRow = ({ txItem, onClick, className }: TxListRrowProps) => {
    const { t } = useTranslation()

    return (
        <Row className={className} onClick={onClick}>
            <Cell
                width={'30rem'}
                desc={t('tx_list_tx_id')}
                shrink
                className="text-left"
            >
                <HashDisplay
                    value={txItem.hash}
                    showCopyIcon={{ mobile: false, desktop: 'lg' }}
                    overflow={{ mobile: 'wrap', desktop: 'elipse' }}
                />
            </Cell>
            <Cell
                width={'11rem'}
                desc={t('tx_list_value')}
                className="md:font-bold"
                shrink
            >
                <CurrencyDisplay wei={txItem.value} />
            </Cell>
            {/* <Cell
                width={'9rem'}
                desc={t('tx_list_timestamp')}
                className="md:font-bold"
            >
                <span>
                    <LiveTimestampDisplay value={txItem.date} />
                </span>
            </Cell> */}
            {/* <Cell width={'7rem'} desc={t('tx_list_size')}>
                <ByteSizeDisplay value={0} />
            </Cell> */}
            <Cell width="2rem" className="hidden md:flex justify-center">
                <AngleRight
                    className="h-6"
                    preserveAspectRatio="xMidYMid slice"
                />
            </Cell>
        </Row>
    )
}

interface TxListDisplayProps {
    txs: TransactionListItem[] | undefined
    isLoading?: boolean
    loadingItems?: number
    className?: string
}

const TxListDisplay = ({
    txs,
    isLoading = false,
    loadingItems = ITEMS_PER_FETCH,
    className = ''
}: TxListDisplayProps) => {
    const navigate = useNavigate()
    const { t } = useTranslation()

    return (
        <>
            <Table className={className}>
                <Header>
                    <HeaderCell width={'30rem'} shrink className="text-left">
                        {t('tx_list_tx_id')}
                    </HeaderCell>
                    <HeaderCell width={'11rem'}>
                        {t('tx_list_value')}
                    </HeaderCell>
                    {/* <HeaderCell width={'9rem'}>
                        {t('tx_list_timestamp')}
                    </HeaderCell> */}
                    {/* <HeaderCell width={'7rem'}>{t('tx_list_size')}</HeaderCell> */}
                    <HeaderCell width="2rem" />
                </Header>

                {txs?.map((txData) => (
                    <TxListRow
                        key={txData.hash}
                        txItem={txData}
                        onClick={() => navigate(`/transactions/${txData.hash}`)}
                    />
                ))}

                {isLoading &&
                    Array(loadingItems)
                        .fill(0)
                        .map((_, idx) => <TxListRowLoading key={idx} />)}
            </Table>
        </>
    )
}

interface TxListProps {
    className?: string
}

const TxList = ({ className = '' }: TxListProps) => {
    const { data, isLoading, loader, hasMore } =
        useTransactionList(ITEMS_PER_FETCH)

    useEffect(() => {
        loader()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <TxListDisplay
                className={className}
                txs={data}
                isLoading={isLoading}
            />
            {hasMore && (
                <div className="w-full my-10 flex justify-center">
                    <LoadMoreButton
                        onClick={loader}
                        disabled={isLoading}
                        isLoading={isLoading}
                    />
                </div>
            )}
        </>
    )
}

export default TxList
export { TxListDisplay }
