// components
import { Row, Cell } from 'components/Table'
import {
    Filler,
    TextPlaceholder,
    HashPlaceholder
} from 'components/Decoration/Fillter'

// assets
import { ReactComponent as AngleRight } from 'images/icon-angle_right.svg'

const TxListRow = ({ className = '' }: { className?: string }) => (
    <Row className={className}>
        <Cell
            className="animate-pulse text-left"
            width={'30rem'}
            desc={<Filler>Transaction ID</Filler>}
            shrink
        >
            <HashPlaceholder className="w-full md:w-auto md:mx-2" />
        </Cell>
        <Cell
            className="animate-pulse md:font-bold"
            width={'11rem'}
            desc={<Filler>Value</Filler>}
            shrink
        >
            <TextPlaceholder className="w-full md:w-auto md:mx-2" />
        </Cell>
        <Cell
            className="animate-pulse md:font-bold"
            width={'9rem'}
            desc={<Filler>Timestamp</Filler>}
        >
            <TextPlaceholder className="w-full md:w-auto md:mx-2" />
        </Cell>
        {/* <Cell width={'7rem'} desc={<Filler>Size</Filler>}>
            <TextPlaceholder className="w-full md:w-auto md:mx-2" />
        </Cell> */}
        <Cell width="2rem" className="hidden md:flex justify-center">
            <AngleRight className="h-6" preserveAspectRatio="xMidYMid slice" />
        </Cell>
    </Row>
)

export { TxListRow }
