import React from 'react'

// assets
import { ReactComponent as Logo } from 'images/BTQ-logo_on-dark.svg'
import { ReactComponent as Envelope } from 'images/icon-envelope.svg'
import { ReactComponent as Location } from 'images/icon-location.svg'

interface InfoItemProps {
    Icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
    primary?: React.ReactNode
    alt?: React.ReactNode
    className?: string
}

const InfoItem = ({
    Icon,
    primary = '',
    alt = '',
    className = ''
}: InfoItemProps) => (
    <div className={`min-w-0 flex flex-col ${className}`}>
        <div className="flex items-center">
            {Icon && <Icon className="h-4 w-4 text-primary-500 mr-1" />}
            <span className="text-sm font-bold text-on-background">
                {primary}
            </span>
        </div>
        {alt && (
            <span
                className="
                    text-sm font-normal 
                    text-on-background-helper 
                    ml-5 break-words"
            >
                {alt}
            </span>
        )}
    </div>
)

interface CompanyInfoProps {
    className?: string
}

const CompanyInfo = ({ className = '' }: CompanyInfoProps) => (
    <div className={className}>
        <Logo className="h-10 md:h-12" />
        <div className="mt-4 md:mt-5 grid gap-y-2.5">
            <InfoItem Icon={Envelope} primary="E-mail: desk@btq.com" />
            <InfoItem primary="Offices:" />
            <InfoItem
                Icon={Location}
                primary={
                    <a
                        href="https://goo.gl/maps/rhGE8YZhCDPBw2Cy6"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Vancouver, Canada
                    </a>
                }
                alt="25th Floor, 700 West Georgia Street, Vancouver, BC V7Y 1B3"
            />
            <InfoItem
                Icon={Location}
                primary={
                    <a
                        href="https://goo.gl/maps/XypwXrqrTsvqvkLK9"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Vaduz, Liechtenstein
                    </a>
                }
                alt="Dr. Grass-Strasse 12 9490 Vaduz Liechtenstein"
            />
            <InfoItem
                Icon={Location}
                primary={
                    <>
                        <a
                            href="https://goo.gl/maps/i2E7QpKu6cvfuo9M6"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Taipei, Tawian
                        </a>
                    </>
                }
                alt="12F.-1, No. 30, Beiping E. Rd., Zhongzheng Dist., Taipei City 10049, Taiwan (R.O.C.)"
            />
            <InfoItem
                Icon={Location}
                primary={
                    <>
                        <a
                            href="https://goo.gl/maps/9vVh6HvPutBtxJHYA"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Sydney, Australia
                        </a>
                    </>
                }
                alt="Central Station, 1 Eddy Ave, Haymarket NSW 2000"
            />
        </div>
    </div>
)

export default CompanyInfo
