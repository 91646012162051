import { useTranslation } from 'react-i18next'

import { useNetworkStats } from '../hooks'

interface InfoBlockProps {
    stat: string
    value?: number | string
}

interface NetworkStatsProps {
    className?: string
}

const InfoBlock = ({ stat, value }: InfoBlockProps) => {
    return (
        <div
            className="
            text-black font-bold
            w-full px-5 py-3
            border-r
            border-r-black last-of-type:border-r-transparent
            flex flex-col justify-between
            md:flex-row md:justify-between md:items-center"
        >
            <div
                className={`
                    text-left
                    text-xs md:text-xl
                    flex md:flex-col`}
            >
                {stat.split(' ').map((word) => (
                    <span
                        className="whitespace-pre"
                        key={word}
                    >{`${word} `}</span>
                ))}
            </div>
            <span className="text-base md:text-4xl">
                {value?.toLocaleString()}
            </span>
        </div>
    )
}

const NetworkStats = ({ className = '' }: NetworkStatsProps) => {
    const { t } = useTranslation()
    const networkStats = useNetworkStats()
    return (
        <div
            className={`
            md:h-28
            rounded-xl
            bg-gradient-to-br from-primary-alt-500 to-primary-500
            grid grid-flow-col auto-cols-fr
            ${className}`}
        >
            <InfoBlock stat={'Signature Compression'} value={'11.2x'} />
            <InfoBlock
                stat={t('stat_total_blocks')}
                value={networkStats.totalBlocks}
            />
            {/* <InfoBlock
                stat={t('stat_total_transactions')}
                value={networkStats.totalTransactions}
            /> */}
        </div>
    )
}

export default NetworkStats
