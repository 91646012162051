// default values for metadata

import icon from 'images/favicon.png'
import thumbnail from 'images/page_thumbnail.png' // TODO: adjust dimenstin

export const title = 'Explorer' // titleTemplate = '%s | BTQ'
export const description = 'Latest Txs & blocks in BTQ'
export const favicon = icon

export const metaData = function (
    pageTitle = title,
    desc = description
): Array<[string, string]> {
    return [
        ['description', desc],

        // Open Graph Protocal (ref: https://ogp.me/)
        ['og:type', 'website'],
        ['og:title', pageTitle],
        ['og:site_name', pageTitle],
        ['og:description', desc],
        // ['og:url', '???'],
        ['og:image', thumbnail],

        // Twitter (ref: https://developer.twitter.com/en/docs/twitter-for-websites/cards/guides/getting-started)
        ['twitter:card', 'summary_large_image'], // show big thumbnail (no description)
        ['twitter:description', desc],
        ['twitter:image', thumbnail],
        ['twitter:site', '@btq_ag'],
        ['twitter:creator', '@btq_ag'],

        // robots (ref: https://developers.google.com/search/docs/advanced/robots/robots_meta_tag)
        ['robots', 'all']
    ]
}
