import { useMemo } from 'react'
import ClassHelper from 'utils/ClassHelper'

interface linkProps {
    name: string
    href?: string
    onclick?: () => void
}

interface LinkGroupProp {
    title: string
    links: linkProps[]
    className?: string
}

const LinkGroup = ({ title, links, className = '' }: LinkGroupProp) => {
    const lgClass = useMemo(
        () =>
            new ClassHelper(className)
                .include('min-w-max')
                .include('flex flex-col')
                .include('text-base font-semibold')
                .fallback('text-(left|center|right|justify)', 'text-left')
                .fallback('md:text-(left|center|right|justify)', 'md:text-left')
                .toString(),
        [className]
    )

    return (
        <div className={lgClass}>
            <p>{title}</p>
            <ul
                className="
                    grid
                    gap-y-2 md:gap-y-6
                    mt-2 md:mt-6"
            >
                {links.map((link) => (
                    <li
                        key={link.name}
                        className="
                        text-on-surface-helper cursor-pointer"
                    >
                        <a
                            href={link.href}
                            onClick={link.onclick}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {link.name}
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default LinkGroup
