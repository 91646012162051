const ELLIPSIS = '...'

type TypePages = {
    displayAs: string
    num?: number
    itemIndex?: number
}

const getPageItem = (page: number, itemsPerPage: number) => {
    return {
        displayAs: `${page}`,
        num: page,
        itemIndex: (page - 1) * itemsPerPage
    }
}

const getPageList = (
    totalPages: number,
    currentPage: number = 1,
    itemsPerPage: number = -1
) => {
    // show pages if totalPages at Max 5 pages
    if (totalPages <= 7) {
        const pages = Array.from(Array(totalPages).keys()).map((idx) => idx + 1)
        return pages.map((page) => ({
            displayAs: `${page}`,
            num: page,
            itemIndex: (page - 1) * itemsPerPage
        }))
    }

    const pageSet = new Set<number>()

    // add first / last page
    pageSet.add(1)
    pageSet.add(totalPages)

    switch (currentPage) {
        case 1:
        case 2:
        case 3:
        case 4:
            // 1 2 3 4 5 ... 10
            for (let page = 1; page <= 5; page++) {
                pageSet.add(page)
            }

            break

        case totalPages - 3:
        case totalPages - 2:
        case totalPages - 1:
        case totalPages:
            // 1 ... 6 7 8 9 10
            for (let page = totalPages - 4; page <= totalPages; page++) {
                pageSet.add(page)
            }
            break

        default:
            // 1 ... 5 6 7 ... 10
            for (let page = currentPage - 1; page <= currentPage + 1; page++) {
                pageSet.add(page)
            }
            break
    }

    const _pageList = Array.from(pageSet).sort((a, b) => a - b) // sorted list of pages
    const pageList = Array<TypePages>()

    // add ELLIPSIS
    for (let index = 0; index < _pageList.length - 1; index++) {
        pageList.push(getPageItem(_pageList[index], itemsPerPage))

        if (_pageList[index + 1] - _pageList[index] > 1) {
            pageList.push({ displayAs: ELLIPSIS })
        }
    }
    pageList.push(getPageItem(totalPages, itemsPerPage))

    return pageList
}

export { getPageList }
export type { TypePages }
