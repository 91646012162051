import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import box from './box.module.css'

// component
import { ElipseHashDisplay } from 'components/display/Hash'
import { ByteSizeDisplay } from 'components/display/Byte'
import { BouncingEllipses } from 'components/Decoration'
import { TextPlaceholder } from 'components/Decoration/Fillter'

import { PATH } from 'constants/url'

interface BlockInfoItemProps {
    desc: React.ReactNode
    value: React.ReactNode
    className?: string
    setRef?: React.LegacyRef<HTMLDivElement>
}

const BlockInfoItem = ({
    desc,
    value,
    setRef,
    className = ''
}: BlockInfoItemProps) => (
    <div
        ref={setRef}
        className={`
            font-semibold whitespace-nowrap overflow-hidden
            flex items-center justify-between
            ${className}`}
    >
        <span className="flex-initial min-w-0 text-lg">{desc}</span>
        <span className="flex-auto min-w-0 text-xs ml-2 flex justify-end">
            {value}
        </span>
    </div>
)

const SizeItem = ({
    size,
    className = ''
}: {
    size?: number
    className?: string
}) => (
    <div
        className={`
            text-primary-500
            flex items-end justify-between
            ${className}`}
    >
        <span className="font-semibold text-lg">Signature Size</span>
        <span className="font-bold text-3xl">
            <ByteSizeDisplay value={size} />
        </span>
    </div>
)

interface BlockInfoProps {
    height: number
    hash: string | null
    timestamp: string
    transactions: number
    size: number
    aggSignature: string
    signatureMinedByBlock?: number
    setAggRef?: React.LegacyRef<HTMLDivElement>
    setTxsRef?: React.LegacyRef<HTMLDivElement>
    className?: string
}

const BlockInfo = ({
    height,
    hash,
    transactions,
    size,
    aggSignature,
    signatureMinedByBlock,
    setAggRef,
    setTxsRef,
    className,
    timestamp
}: BlockInfoProps) => {
    const navigate = useNavigate()
    const [showAgg, setShowAgg] = useState(false)
    const [aggLitUp, setAggLitUp] = useState(false)

    useEffect(() => {
        const timer = setTimeout(() => {
            // hide animation when block is unconfirmed
            setAggLitUp(true)
        }, (Math.min(transactions, 5) + 0.5) * 1000)

        return () => clearTimeout(timer)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []) // trigger only on page load

    return (
        <div
            className={`
                flex items-center
                ${className}`}
        >
            <div
                className={`
                    w-full min-w-0
                    px-11 py-7
                    bg-gradient-to-b from-[#0F1324] to-surface
                    border-2 border-black ${box.boxDeep}
                    rounded-md
                    flex flex-col relative`}
            >
                <span className="font-bold text-4xl text-center">{`Block ${height}`}</span>
                <span
                    className="
                        font-semibold text-sm
                        text-on-surface-helper
                        mt-3 flex justify-center"
                >
                    {hash ? (
                        <ElipseHashDisplay value={hash} />
                    ) : (
                        <>
                            collecting <BouncingEllipses />
                        </>
                    )}
                </span>

                <div className="mt-6 grid gap-y-4">
                    <BlockInfoItem desc="Timestamps" value={timestamp} />
                    <BlockInfoItem
                        desc="Transactions"
                        value={transactions}
                        setRef={setTxsRef}
                    />
                    {signatureMinedByBlock && (
                        <div
                            onClick={() =>
                                navigate(
                                    `${PATH.BLOCK}/${signatureMinedByBlock}`
                                )
                            }
                        >
                            <BlockInfoItem
                                desc="Aggregated by block"
                                value={signatureMinedByBlock}
                            />
                        </div>
                    )}
                    {/* <BlockInfoItem desc="Status" value={status} /> */}
                    <SizeItem size={size} />
                </div>
                <div
                    className={`mt-8
                        pt-[31%] w-full
                        relative
                        cursor-pointer
                        duration-300
                        ${showAgg ? 'opacity-0' : 'opacity-100'}`}
                    onClick={() => setShowAgg(!showAgg)}
                    ref={setAggRef}
                >
                    <div // border
                        className={`
                            absolute inset-0
                            rounded-xl
                            border-2 border-primary-alt-500
                            duration-1000
                            ${aggLitUp ? 'opacity-0' : 'opacity-100'}`}
                    ></div>
                    <div // background
                        className={`
                            absolute inset-0
                            rounded-xl
                            bg-gradient-tint from-primary-alt-500 to-primary-500
                            shadow-glow
                            duration-1000
                            ${aggLitUp ? 'opacity-100' : 'opacity-0'}
                        `}
                    ></div>
                    <span // [ Aggregate Signature ] text
                        className={`
                            ${showAgg ? 'invisible' : 'visible'}
                            font-bold text-[clamp(0.75rem,0.88vw+0.75rem,2rem)]
                            ${
                                aggLitUp
                                    ? 'text-on-primary'
                                    : 'text-primary-alt-500'
                            }
                            w-max absolute
                            translate-x-1/2 right-1/2
                            translate-y-1/2 bottom-1/2`}
                    >
                        Aggregate Signature
                    </span>
                </div>
                <div
                    className={`
                        absolute inset-x-10 bottom-4
                        duration-300
                        ${showAgg ? 'visible' : 'invisible'}
                        ${showAgg ? 'opacity-100' : 'opacity-30'}
                        ${showAgg ? 'top-4' : 'top-[80%]'}
                        cursor-pointer`}
                    onClick={() => setShowAgg(!showAgg)}
                >
                    <div
                        className={`
                            h-full
                            flex flex-col
                            text-on-primary
                            p-2 rounded-xl
                            bg-gradient-tint from-primary-alt-500 to-primary-500
                            shadow-glow`}
                    >
                        <span className="flex-initial font-bold text-[clamp(0.75rem,0.88vw+0.75rem,2rem)] w-full text-center">
                            Aggregate Signature
                        </span>
                        <div className="flex-auto font-semibold text-sm break-all overflow-y-auto m-2">
                            <div>{aggSignature}</div>
                        </div>
                    </div>
                </div>
                <div // shadow
                    className="
                        absolute inset-x-2 h-6 -bottom-20
                        bg-gradient-radial from-on-background-helper/40 to-transparent"
                ></div>
            </div>
        </div>
    )
}

const BlockInfoLoading = ({
    height,
    className = ''
}: Partial<BlockInfoProps>) => (
    <div
        className={`
            flex items-center
            ${className}`}
    >
        <div
            className={`
                    w-full min-w-0
                    px-11 py-7
                    bg-gradient-to-b from-[#0F1324] to-surface
                    border-2 border-black ${box.boxDeep}
                    rounded-md
                    flex flex-col relative`}
        >
            <span className="font-bold text-4xl text-center">{`Block ${
                height || ''
            }`}</span>
            <span
                className="
                    font-semibold text-sm
                    text-on-surface-helper
                    mt-3 flex justify-center
                    animate-pulse"
            >
                <TextPlaceholder className="w-full" />
            </span>
            <div className="mt-6 grid gap-y-4 animate-pulse">
                <TextPlaceholder className="w-full" />
                <TextPlaceholder className="w-full" />
                <TextPlaceholder className="w-full" />
                <TextPlaceholder className="w-full" />
            </div>
            <div
                className="
                    mt-8
                    pt-[31%] w-full
                    text-[clamp(0.75rem,0.88vw+0.75rem,2rem)]
                    rounded-xl border-2 border-primary-alt-500/30
                    animate-pulse
                    relative"
            ></div>

            <div // shadow
                className="
                    absolute inset-x-2 h-6 -bottom-20
                    bg-gradient-radial from-on-background-helper/40 to-transparent"
            ></div>
        </div>
    </div>
)

export { BlockInfo, BlockInfoLoading }
