class ClassHelper {
    readonly classes: Array<string>

    constructor()
    constructor(classes: string)
    constructor(classes: string[])
    constructor(classes?: unknown) {
        if (typeof classes === 'string') {
            this.classes = classes.split(' ')
        } else if (Array.isArray(classes)) {
            this.classes = classes
        } else {
            this.classes = []
        }
    }

    include(...extraClasses: string[]): ClassHelper {
        return new ClassHelper(this.classes.concat(extraClasses))
    }

    only(...regex: string[]): ClassHelper {
        let regexp = regex.map((str) => new RegExp(str))

        let classes = this.classes.filter((className) =>
            regexpUnion(regexp, className)
        )

        return new ClassHelper(classes)
    }

    exclude(...regex: string[]): ClassHelper {
        let regexp = regex.map((str) => new RegExp(str))
        let classes = [...this.classes]

        classes = classes.filter((className) => !regexpUnion(regexp, className))

        return new ClassHelper(classes)
    }

    fallback(regex: string, value: string) {
        let regexp = new RegExp(regex)

        for (const className of this.classes) {
            if (regexp.test(className)) {
                return this
            }
        }

        return this.include(value)
    }

    toString(): string {
        return this.classes.join(' ')
    }
}

function regexpUnion(regex: RegExp[], str: string): boolean {
    for (const re of regex) {
        if (re.test(str)) {
            return true
        }
    }
    return false
}

export default ClassHelper
