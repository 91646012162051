export const numberToHexStr = (num: number) => `0x${num.toString(16)}`
export const generateRandomHexString = (size: number): string =>
    '0x' +
    [...Array(size)]
        .map(() => Math.floor(Math.random() * 16).toString(16))
        .join('')

const yesterday = new Date()
yesterday.setDate(yesterday.getDate() - 1)
yesterday.setUTCHours(0, 0, 0, 0)
const BASE_TIME = yesterday.valueOf() / 1000

export const getBlockTime = (blockNum: number): string =>
    numberToHexStr(BASE_TIME + 15 * 60 * blockNum)
