import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { LANGUAGE_CODE } from 'constants/i18n'

// translations
import TRANSLATION_EN from 'locales/en/translation.json'
import TRANSLATION_ZH_TW from 'locales/zh-TW/translation.json'

i18n
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    .init({
        resources: {
            [LANGUAGE_CODE.EN]: { translation: TRANSLATION_EN },
            [LANGUAGE_CODE.ZH_TW]: { translation: TRANSLATION_ZH_TW }
        },

        fallbackLng: LANGUAGE_CODE.EN,
        debug: false, // TODO: should be able to toggle according to current environment

        interpolation: {
            escapeValue: false // not needed for react as it escapes by default
        },

        // will not dsplay {{value}} when it's undefined
        missingInterpolationHandler: function (text, value, options) {
            return ''
        }
    })

export default i18n
