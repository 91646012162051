import { RLP } from '@ethereumjs/rlp'
import { Buffer } from 'buffer'
import { InfoItem } from 'components/Card/Panel'
// import AggSignature from 'components/Visual/AggSignature'
import { useMemo } from 'react'

const VERSION_LENGTH = 2

export const DecodedAggregateSignature = ({
    signature
}: {
    signature?: string
}) => {
    const [version, blockNumber, aggSig] = useMemo(() => {
        try {
            if (!signature) {
                return []
            }
            const stripped = signature.replace('0x', '')
            const version = stripped.slice(0, VERSION_LENGTH)
            const data = stripped.slice(VERSION_LENGTH)

            const decoded = Buffer.from(data, 'hex')
            const result = RLP.decode(decoded) as Uint8Array[]
            const blockNumber = Buffer.from(result[0]).toString('hex')
            const aggSig = Buffer.from(result[1]).toString('hex')
            return [version, blockNumber, aggSig]
        } catch (e) {
            // console.error(e)
            return []
        }
    }, [signature])

    const aggSigBlockNumber = useMemo(
        () => (blockNumber ? parseInt(blockNumber, 16) : undefined),
        [blockNumber]
    )

    return (
        <>
            {version && (
                <InfoItem desc={'Metadata Version'} content={version} />
            )}
            {aggSig && (
                <InfoItem
                    showCopyIcon
                    desc={`Aggregate Signature for Block ${aggSigBlockNumber}`}
                    content={aggSig}
                />
            )}
        </>
    )
}
