import React from 'react'

const s = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB']

const readablizeBytes = (bytes: number | undefined) => {
    if (typeof bytes !== 'number' || bytes <= 0) {
        return '--'
    }

    const e = Math.floor(Math.log(bytes) / Math.log(1024))
    return roundTo2Dec(bytes / Math.pow(1024, e)) + ' ' + s[e]
}

const ByteSizeDisplay = React.memo(function byteSizeDisplay({
    value
}: {
    value?: number
}) {
    return <>{readablizeBytes(value)}</>
})

export { readablizeBytes, ByteSizeDisplay }

function roundTo2Dec(value: number): number {
    // to ensure things like 1.005 round correctly,
    return Math.round((value + Number.EPSILON) * 100) / 100
}
