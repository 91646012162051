import React from 'react'
import box from './box.module.css'

// component
import { ElipseHashDisplay } from 'components/display/Hash'
import { CurrencyDisplay } from 'components/display/Currency'
import { ByteSizeDisplay } from 'components/display/Byte'
import { Filler, TextPlaceholder } from 'components/Decoration/Fillter'

// assets
import { ReactComponent as Ellipsis } from 'images/tx_ellipsis.svg'
import { ReactComponent as Arrow } from 'images/tx_arrow.svg'

const TxInfoItem = ({
    desc,
    value
}: {
    desc: React.ReactNode
    value?: React.ReactNode
}) => (
    <>
        <span className="min-w-0 font-bold text-on-background">{desc}</span>
        <span className="min-w-0 font-semibold text-on-background-helper">
            {value}
        </span>
    </>
)

interface TxItemProps {
    hash?: string
    value?: string
    setSigRef?: React.LegacyRef<HTMLDivElement>
    className?: string
}

const TxItem = React.forwardRef<HTMLDivElement, TxItemProps>(function txItem(
    { hash, value, setSigRef, className },
    ref
) {
    return (
        <div
            className={`
                min-w-0
                pl-8 pr-5 py-3
                bg-[#17182B] rounded-md drop-shadow-lg
                flex justify-between items-center
                ${className}`}
        >
            <div
                className="
                    flex-auto min-w-0
                    text-xs
                    grid gap-y-1"
            >
                <TxInfoItem
                    desc="Transaction ID"
                    value={hash && <ElipseHashDisplay value={hash} />}
                />
                <TxInfoItem
                    desc="Value"
                    value={value && <CurrencyDisplay wei={value} />}
                />
            </div>
            <div className="flex-auto w-16"></div>
            <div
                className="
                    h-12 w-36 flex-none
                    border rounded-md border-primary-alt-500 
                    text-primary-alt-500 text-lg font-bold
                    flex justify-center items-center"
                ref={ref || setSigRef}
            >
                Signature
            </div>
        </div>
    )
})

const TxItemLoading = ({ className = '' }: TxItemProps) => {
    return (
        <div
            className={`
                    min-w-0
                    pl-8 pr-5 py-3
                    bg-[#17182B] rounded-md drop-shadow-lg
                    flex justify-between items-center
                    ${className}`}
        >
            <div
                className="
                        flex-auto min-w-0
                        text-xs
                        grid gap-y-1"
            >
                <TxInfoItem
                    desc={<Filler>Transaction ID</Filler>}
                    value={<TextPlaceholder className="w-full" />}
                />
                <TxInfoItem
                    desc={<Filler>Value</Filler>}
                    value={<TextPlaceholder className="w-full" />}
                />
            </div>
            <div className="flex-initial w-10"></div>
            <div
                className="
                    h-12 w-36 flex-none
                    border rounded-md border-primary-alt-500/30
                    flex justify-center items-center"
            ></div>
        </div>
    )
}

const TxEllipsis = ({ className = '' }: { className?: string }) => (
    <div className={`h-10 flex justify-center animate-pulse ${className}`}>
        <Ellipsis className="w-2" />
    </div>
)

interface TxBlockProps {
    size: number
    setArrowRef?: React.LegacyRef<SVGSVGElement>
    className?: string
    children?: React.ReactNode
}

const TxBlock = React.forwardRef<HTMLDivElement, TxBlockProps>(function TxBlock(
    { size, setArrowRef, className = '', children },
    ref
) {
    return (
        <div
            ref={ref}
            className={`
                pl-9 pr-14 pt-8 pb-7 
                border-2 border-black
                bg-surface rounded-lg
                grid gap-y-3 relative
                ${box.boxShallow}
                ${className}`}
        >
            <div className="text-primary-500">
                <span className="text-lg font-semibold">Size</span>
                <span className="text-3xl font-bold ml-2">
                    <ByteSizeDisplay value={size} />
                </span>
            </div>
            {children}
            <Arrow
                preserveAspectRatio="xMinYMin meet"
                className="
                    h-16
                    hidden absolute
                    z-10
                    -translate-x-1 -translate-y-8"
                ref={setArrowRef}
            />
        </div>
    )
})

const TxBlockLoading = ({ className = '' }: { className?: string }) => (
    <div
        className={`
            pl-9 pr-14 pt-8 pb-7 
            border-2 border-black
            bg-surface rounded-lg
            grid gap-y-3 relative
            ${box.boxShallow}
            ${className}`}
    >
        <TxItemLoading className="animate-pulse" />
        <TxItemLoading className="animate-pulse" />
        <TxItemLoading className="animate-pulse" />
        <TxEllipsis className="animate-pulse" />
        <Arrow
            preserveAspectRatio="xMinYMin meet"
            className="
                h-16
                absolute
                z-10
                left-full
                -translate-y-8 top-1/2"
        />
    </div>
)

const showArrow = (
    arrow: SVGSVGElement,
    base: HTMLElement,
    pointTo: HTMLElement
) => {
    const baseRect = base.getBoundingClientRect()
    const toRect = pointTo.getBoundingClientRect()
    const toABSY = (toRect.top + toRect.bottom) / 2

    arrow.style.display = 'block'
    arrow.style.top = `${toABSY - baseRect.top}px`
    arrow.style.left = `${baseRect.width}px`
}

export { TxItem, TxEllipsis, TxBlock, TxBlockLoading, showArrow }
