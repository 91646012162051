import React from 'react'

// components
// import { ElipseableText } from 'components/Text'
import { CopyIcon } from 'components/Decoration'
import { ElipseableText } from 'components/Text'

interface BasicInfoItemDescPros {
    desc: string | React.ReactNode
    className?: string
}

const BasicInfoItemDesc = ({ desc, className = '' }: BasicInfoItemDescPros) => (
    <div className={`${className}`}>
        {typeof desc === 'string' ? (
            <span
                className="
                    md:gradient-text
                    from-primary-alt-500 to-primary-500
                    selection:text-primary-500"
            >
                {desc}
            </span>
        ) : (
            <>{desc}</>
        )}
    </div>
)

interface BasicInfoItemContentPros {
    content: string | string[] | React.ReactNode | React.ReactNode[]
    showCopyIcon?: boolean
    className?: string
    onClick?: () => void
}

const BasicInfoItemContent = ({
    content,
    showCopyIcon = false, // only when content is string or string[]
    className = '',
    onClick
}: BasicInfoItemContentPros) => {
    const contentList = Array.isArray(content) ? content : [content]
    return (
        <div
            className={`
                min-w-0
                grid auto-rows-max
                gap-y-5 md:gap-y-2
                justify-items-end
                md:justify-items-start
                ${className}`}
            onClick={onClick}
        >
            {contentList.map((content, index) => {
                if (typeof content !== 'string') {
                    return (
                        <React.Fragment key={index}>{content}</React.Fragment>
                    )
                }
                const childrenElement = <ElipseableText value={content} />

                return (
                    <div key={index} className="w-full min-w-0">
                        {showCopyIcon ? (
                            <CopyIcon textToCopy={content}>
                                {childrenElement}
                            </CopyIcon>
                        ) : (
                            childrenElement
                        )}
                    </div>
                )
            })}
        </div>
    )
}

interface BasicInfoItemProps {
    desc: string | React.ReactNode
    content: string | string[] | React.ReactNode | React.ReactNode[]

    showCopyIcon?: boolean // only when content is string or string[]

    className?: string
    descClassName?: string
    contentClassName?: string
    onClick?: () => void
}

const BasicInfoItem = ({
    desc,
    content,
    showCopyIcon = false,
    className = '',
    descClassName = '',
    contentClassName = '',
    onClick
}: BasicInfoItemProps) => (
    <div
        className={`
            min-w-0
            flex md:flex-col
            ${className}`}
    >
        {/* desc part: desktop & mobile */}
        <BasicInfoItemDesc
            desc={desc}
            className={`w-24 flex-none md:w-full ${descClassName}`}
        />
        {/* content part */}
        <BasicInfoItemContent
            content={content || ''}
            className={`flex-1 ${contentClassName}`}
            showCopyIcon={showCopyIcon}
            onClick={onClick}
        />
    </div>
)

export { BasicInfoItemDesc, BasicInfoItemContent, BasicInfoItem }
export type { BasicInfoItemProps }
