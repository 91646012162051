import { memo } from 'react'

import { covertWeiToEther } from 'utils/unitConversion'

/**
 * Wei is the smallest unit in Ethereum
 * We use the same counting system as Ethereum for now
 */
interface Props {
    wei?: string | number
}

export const CurrencyDisplay = memo(function currenyFormat({ wei }: Props) {
    if (!wei || typeof wei !== 'string') {
        return <></>
    }

    const amount = covertWeiToEther(wei)
    return <>{`${amount} BTQ`}</>
})
