import axios from 'axios'

import { getApiEndPoint } from './utils/endpoint'
import { timestampToDate } from '../utils/createDate'

import {
    getMockBlockDetail,
    BLOCK_HASH_TO_NUMBER,
    fakeTxData,
    mockBlockTxs,
    mockAccountTx,
    mockAccountStat
} from './mock2'
import { MAX_BLOCK_COUNT, TX_PER_BLOCK } from './mock2/constants'

import {
    // TransactionListItemApiPayload,
    TransactionListItem,
    // BlockListItemApiPayload,
    BlockListItem,
    PaginationPayload,
    // BlockDetailApiPayload,
    BlockDetail,
    // TransactionDetailApiPayload,
    TransactionDetail,
    AccountStatPayload,
    AccountTransactionPayload,
    AccountTransactionItem
} from './types'

const apiInstance = axios.create({
    baseURL: getApiEndPoint(),
    timeout: 5000
})

export const getBlockList = async (
    count: number,
    offset: number,
    startFrom?: number
): Promise<PaginationPayload<BlockListItem>> => {
    const total = MAX_BLOCK_COUNT
    const result = []

    for (let i = 0; i < count; i++) {
        const blockNumber = (startFrom || MAX_BLOCK_COUNT) - offset - i

        const blockInfo = getMockBlockDetail(blockNumber)
        result.push({
            number: blockInfo.number,
            hash: blockInfo.hash,
            timestamp: blockInfo.timestamp,
            transactionCounts: blockInfo.transactions.length
        })
    }

    const data = {
        total,
        take: count,
        offset,
        result: result
    }

    const ret = {
        ...data,
        result: data.result.map((block) => {
            return {
                ...block,
                date: timestampToDate(block.timestamp)
            }
        })
    }
    return ret
}
export const getTransactionList = async (
    count: number,
    offset: number,
    startFrom?: number
): Promise<PaginationPayload<TransactionListItem>> => {
    const mockTxs = mockBlockTxs.get(MAX_BLOCK_COUNT - 1)
    const mockBlock = getMockBlockDetail(MAX_BLOCK_COUNT - 1)

    const mockData = {
        result:
            mockTxs?.slice(offset, offset + count).map((tx, i) => ({
                ...tx,
                data: new Date(),
                id: i,
                date: timestampToDate(mockBlock.timestamp),
                block: {
                    height: mockBlock.number,
                    hash: mockBlock.hash || '',
                    timestamp: mockBlock.timestamp
                }
            })) || [],
        total: TX_PER_BLOCK * MAX_BLOCK_COUNT,
        take: count,
        offset
    }
    return mockData
}

export const getLatestBlocks = async (
    count: number
): Promise<PaginationPayload<BlockListItem>> => {
    const res = await getBlockList(count, 0)
    return res
}

export const getCandidateBlock = async (): Promise<BlockListItem> => {
    const res = await getBlockList(1, 0) // get lateest one block
    return res.result[0]
}

export const getLatestTransactions = async (
    count: number
): Promise<PaginationPayload<TransactionListItem>> => {
    const res = await getTransactionList(count, 0)
    return {
        ...res,
        total: res.total
    }
}

export const getBlockDetail = async (
    blockHashOrNumber: string
): Promise<BlockDetail | undefined> => {
    const isHash = blockHashOrNumber.includes('0x')

    const blockNumber: number = isHash
        ? BLOCK_HASH_TO_NUMBER[blockHashOrNumber]
        : Number(blockHashOrNumber)

    const blockDetail = getMockBlockDetail(blockNumber)

    return blockDetail
        ? {
              ...blockDetail,
              date: timestampToDate(blockDetail.timestamp)
          }
        : undefined
}

export const getTransactionDetail = async (
    hash: string
): Promise<TransactionDetail | undefined> => {
    const mockTxData = fakeTxData.get(hash)
    return mockTxData
}

export const getAccountStat = async (
    id: string
): Promise<AccountStatPayload> => {
    const mockState = mockAccountStat(id)
    if (mockState) {
        return mockState
    }

    const res = await apiInstance.get<AccountStatPayload>(`/account/${id}`)
    return res.data
}

export const getAccountTransaction = async (
    id: string,
    count: number,
    offset: number
): Promise<PaginationPayload<AccountTransactionItem>> => {
    const [mockTx, totalAmount] = mockAccountTx(id, count, offset)

    if (mockTx) {
        return {
            result: mockTx,
            total: totalAmount,
            take: count,
            offset: offset
        }
    }

    const { data } = await apiInstance.get<
        PaginationPayload<AccountTransactionPayload>
    >(`/account/${id}/tx?take=${count}&offset=${offset}`)

    return {
        ...data,
        result: data.result.map((tx) => ({
            ...tx,
            timestamp: new Date(tx.created_at)
        }))
    }
}
