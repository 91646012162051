import React from 'react'

// components
import { ElipseHashDisplay } from 'components/display/Hash'

interface WithClassName {
    className: string
    [key: string]: string
}

interface IconProps {
    icon: string | React.FunctionComponent<React.SVGProps<SVGSVGElement>>
    iconAlt?: string
    className?: string
}

const Icon = ({ icon, iconAlt, className = '' }: IconProps) => {
    if (typeof icon !== 'string') {
        const IconElem = icon as React.FC<WithClassName>
        return <IconElem className={`w-9 h-9 md:w-28 md:h-28 ${className}`} />
    } else {
        return (
            <img
                src={icon}
                alt={iconAlt || 'icon'}
                className={`w-9 h-9 md:w-28 md:h-28 ${className}`}
            />
        )
    }
}
interface IconTitlePropps {
    icon: string | React.FunctionComponent<React.SVGProps<SVGSVGElement>>
    iconAlt?: string
    title: string
    desc?: string | React.ReactNode
    className?: string
}

const IconTitle = ({
    icon,
    iconAlt,
    title,
    desc,
    className = ''
}: IconTitlePropps) => (
    <div
        className={`
                w-full
                pr-14 pl-4 md:pl-2
                flex items-center
                ${className}`}
    >
        <div className="flex-none">
            <Icon icon={icon} iconAlt={iconAlt || title} />
        </div>
        <div
            className="
                flex-1 min-w-0
                ml-2 md:ml-4
                flex flex-col
                justify-center"
        >
            <span className="text-base md:text-4xl font-bold">{title}</span>
            {typeof desc === 'string' ? (
                <ElipseHashDisplay
                    className="md:mt-1 text-sm font-semibold"
                    value={desc}
                    showCopyIcon
                />
            ) : (
                <span className="md:mt-1 text-sm font-semibold">{desc}</span>
            )}
        </div>
    </div>
)

export default IconTitle
export { Icon }
