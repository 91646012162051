// components
import Section from 'components/layout/Section'
import Logo from 'components/Header/Logo'
import TopNav from 'components/Header/TopNav'

const Header = () => (
    <header>
        <Section>
            <Logo className="mt-4 md:mt-7" />
            <TopNav className="mt-4 md:mt-16" />
        </Section>
    </header>
)

export default Header
