// components
import NavItems from 'components/Header/NavItems'
import SearchBox from 'components/Header/SearchBox'

interface TopNavProps {
    className?: string
}

const TopNav = ({ className = '' }: TopNavProps) => (
    <div
        className={`
            w-full
            flex items-center
            flex-col-reverse md:flex-row
            ${className}`}
    >
        <NavItems
            className="
                w-full md:min-w-max
                h-9 md:h-14
                mt-4 md:mt-0
                mx-8 md:mx-0"
        />
        <SearchBox
            className="
                w-full md:max-w-lg
                h-10 md:h-14
                md:grow md:ml-12"
        />
    </div>
)

export default TopNav
