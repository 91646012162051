import React, { useState, useCallback } from 'react'

// assets
import { ReactComponent as Copy } from 'images/icon-copy.svg'

const VerticalPositionFlex = {
    top: ['flex-col-reverse', 'justify-center mb-[0.5em]'],
    'top-left': ['flex-col-reverse', 'justify-start mb-[0.5em]'],
    'top-right': ['flex-col-reverse', 'justify-end mb-[0.5em]'],
    bottom: ['flex-col', 'justify-center mt-[0.5em]'],
    'bottom-left': ['flex-col', 'justify-start mt-[0.5em]'],
    'bottom-right': ['flex-col', 'justify-end mt-[0.5em]']
}

const HorizentalPositionFlex = {
    right: ['flex-row', 'ml-[0.5em]'],
    left: ['flex-row-reverse', 'mr-[0.5em]']
}

enum IconSize {
    'xs' = '.75em',
    'sm' = '.875em',
    '1x' = '1em',
    'lg' = '1.333em',
    '2x' = '2em',
    '3x' = '3em'
}
type CopyIconSize = keyof typeof IconSize

interface BasicCopyIconProps {
    textToCopy: string
    iconPos?: string
    size?: CopyIconSize
    className?: string
}

const CopyButton = ({
    textToCopy,
    className = '',
    size = '1x'
}: {
    textToCopy: string
    className?: string
    size?: CopyIconSize
}) => {
    const [copy, setCopy] = useState(false)
    const onCopy = useCallback(
        (event) => {
            event.stopPropagation()
            setCopy(true)
            navigator.clipboard.writeText(textToCopy)
            setInterval(() => setCopy(false), 1000)
        },
        [textToCopy]
    )

    return (
        // create a new stacking context w/ relative
        <div
            className={`
                relative
                ${className}`}
        >
            <Copy
                className="cursor-pointer inline"
                onClick={onCopy}
                style={{ height: IconSize[size] }}
            />
            {copy && (
                <div
                    className={`
                        text-xs
                        absolute bottom-0 mb-5
                        p-1
                        bg-background
                        rounded-md`}
                >
                    Copied
                </div>
            )}
        </div>
    )
}

interface HorizentalCopyIconProps extends BasicCopyIconProps {
    iconPos?: keyof typeof HorizentalPositionFlex
}

const HorizentalCopyIcon: React.FC<HorizentalCopyIconProps> = ({
    textToCopy,
    iconPos = 'right',
    className = '',
    size = '1x',
    children
}) => (
    <div
        className={`
            flex items-center
            ${HorizentalPositionFlex[iconPos][0]}
            ${className}`}
    >
        {children}
        <CopyButton
            className={HorizentalPositionFlex[iconPos][1]}
            size={size}
            textToCopy={textToCopy}
        />
    </div>
)

interface VerticalCopyIconProps extends BasicCopyIconProps {
    iconPos?: keyof typeof VerticalPositionFlex
}

const VerticalCopyIcon: React.FC<VerticalCopyIconProps> = ({
    textToCopy,
    iconPos = 'bottom-right',
    size = '1x',
    className = '',
    children
}) => (
    <div
        className={`
            flex
            ${VerticalPositionFlex[iconPos][0]}
            ${className}`}
    >
        {children}

        <div className={`w-full flex ${VerticalPositionFlex[iconPos][1]}`}>
            <CopyButton size={size} textToCopy={textToCopy} />
        </div>
    </div>
)

interface CopyIconProps extends BasicCopyIconProps {
    iconPos?:
        | keyof typeof HorizentalPositionFlex
        | keyof typeof VerticalPositionFlex
}

const CopyIcon: React.FC<CopyIconProps> = ({
    children,
    iconPos = 'right',
    ...pros
}) =>
    iconPos in HorizentalPositionFlex ? (
        <HorizentalCopyIcon
            iconPos={iconPos as keyof typeof HorizentalPositionFlex}
            {...pros}
        >
            {children}
        </HorizentalCopyIcon>
    ) : (
        <VerticalCopyIcon
            iconPos={iconPos as keyof typeof VerticalPositionFlex}
            {...pros}
        >
            {children}
        </VerticalCopyIcon>
    )

export { CopyButton, HorizentalCopyIcon, VerticalCopyIcon, CopyIcon }
export type { CopyIconSize }
