import { useMemo } from 'react'
import { title as t, description as d, metaData as m } from 'constants/metaData'

type MetadataType = {
    property: string
    content: string
}[]

const useMetadata = (title = t, description = d): MetadataType => {
    const meta = useMemo(
        () =>
            m(title, description).map(([property, content]) => ({
                property,
                content
            })),
        [title, description]
    )

    return meta
}

export default useMetadata
