import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// components
import Section from 'components/layout/Section'
import Helmet from 'components/Helmet'
import IconTitleMore from 'pages/AccountInfo/IconTitle'
import TxList from 'pages/AccountInfo/TxList'
import { CurrencyDisplay } from 'components/display/Currency'

// assets
import { ReactComponent as AccountIcon } from 'images/circled-account.svg'

// api
import { useAccountStats } from './hooks'

const AccountInfoPage = () => {
    const params = useParams()
    const { t } = useTranslation()
    const { stat } = useAccountStats(params.accountId)

    return (
        <>
            <Helmet
                title={t('account_meta_title')}
                description={t('account_meta_description', {
                    accountId: params.accountId
                })}
            />
            <Section className="mt-5 md:mt-12">
                <IconTitleMore
                    className="mt-5"
                    icon={AccountIcon}
                    title="Address"
                    hash={params.accountId}
                    infoItems={[
                        {
                            desc: 'Balance',
                            content: stat?.balance ? (
                                <CurrencyDisplay wei={stat?.balance} />
                            ) : (
                                '--'
                            )
                        },
                        { desc: 'Nonce', content: stat?.nonce || '--' }
                    ]}
                />
                <TxList className="mt-2 md:mt-7" accountId={params.accountId} />
            </Section>
        </>
    )
}

export default AccountInfoPage
