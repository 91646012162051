import React from 'react'

interface TextProps {
    className?: string
}

const GradientText: React.FC<TextProps> = ({ children, className = '' }) => (
    <span
        className={`
            bg-clip-text text-transparent
            bg-gradient-to-r from-primary-alt-500 to-primary-500
            selection:text-primary-500
            ${className}`}
    >
        {children}
    </span>
)

interface ElipseableTextProps {
    value?: string
    elipsePos?: number
    className?: string
}

const ElipseableText = ({
    value,
    elipsePos = -8,
    className = ''
}: ElipseableTextProps) => {
    if (typeof value !== 'string') {
        return null
    }

    const [part1, part2] = [value.slice(0, elipsePos), value.slice(elipsePos)]
    return (
        <span
            className={`
                overflow-hidden
                select-all
                flex ${className}`}
        >
            <span className="flex-initial truncate">{part1}</span>
            <span className="flex-none">{part2}</span>
        </span>
    )
}

export { GradientText, ElipseableText }
