import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// components
import { HashDisplay } from 'components/display/Hash'
// import { ByteSizeDisplay } from 'components/display/Byte'
import { LiveTimestampDisplay } from 'components/display/Timestamp'
import { Cell, HeaderCell, Row, Header, Table } from 'components/Table'
import { LoadMoreButton } from 'components/Button'
import { BlockListRrow as BlockListRowLoading } from './BlockListLoading'

// assets
import { ReactComponent as AngleRight } from 'images/icon-angle_right.svg'

// api
import { BlockListItem } from 'api/types'
import { useBlockList } from './hooks'

const ITEMS_PER_FETCH = 50

interface BlockListRrowProps {
    blockItem: BlockListItem
    onClick: () => void
    className?: string
}

const BlockListRrow = ({
    blockItem,
    onClick,
    className
}: BlockListRrowProps) => {
    const { t } = useTranslation()

    return (
        <Row className={className} onClick={onClick}>
            <Cell width={'6rem'} desc={t('block_list_height')}>
                {blockItem.number}
            </Cell>
            <Cell width={'32rem'} desc={t('block_list_hash')} shrink>
                <HashDisplay
                    value={blockItem.hash || ''}
                    showCopyIcon={{
                        mobile: false,
                        desktop: 'lg'
                    }}
                    overflow={{
                        mobile: 'wrap',
                        desktop: 'elipse'
                    }}
                />
            </Cell>
            <Cell width={'5rem'} desc={t('block_list_tx_count')}>
                {blockItem.transactionCounts}
            </Cell>
            <Cell
                width={'9rem'}
                desc={t('block_list_timestamp')}
                className="md:font-bold"
            >
                <span>
                    <LiveTimestampDisplay value={blockItem.date} />
                </span>
            </Cell>
            {/* <Cell width={'7rem'} desc={t('block_list_size')}>
                <ByteSizeDisplay value={blockItem.size} />
            </Cell> */}
            <Cell width="2rem" className="hidden md:flex justify-center">
                <AngleRight
                    className="h-6"
                    preserveAspectRatio="xMidYMid slice"
                />
            </Cell>
        </Row>
    )
}

interface BlockListDisplayProps {
    blocks: BlockListItem[] | undefined
    showCollecting?: boolean
    isLoading?: boolean
    loadingItems?: number
    className?: string
}

const BlockListDisplay = ({
    blocks,
    showCollecting = false,
    isLoading = false,
    loadingItems = ITEMS_PER_FETCH,
    className = ''
}: BlockListDisplayProps) => {
    const navigate = useNavigate()
    const { t } = useTranslation()

    return (
        <>
            <Table className={className}>
                <Header>
                    <HeaderCell width={'6rem'}>
                        {t('block_list_height')}
                    </HeaderCell>
                    <HeaderCell width={'32rem'} shrink>
                        {t('block_list_hash')}
                    </HeaderCell>
                    <HeaderCell width={'5rem'}>
                        {t('block_list_tx_count')}
                    </HeaderCell>
                    <HeaderCell width={'9rem'}>
                        {t('block_list_timestamp')}
                    </HeaderCell>
                    {/* <HeaderCell width={'7rem'}>{t('block_list_size')}</HeaderCell> */}
                    <HeaderCell width="2rem" /> {/* MoreInfo */}
                </Header>

                {blocks?.map((block, index) =>
                    block?.hash || showCollecting ? (
                        <BlockListRrow
                            key={block?.hash || index}
                            blockItem={block}
                            onClick={() => {
                                if (block.hash) {
                                    navigate(`/blocks/${block.hash}`)
                                }
                            }}
                        />
                    ) : undefined
                )}

                {isLoading &&
                    Array(loadingItems)
                        .fill(0)
                        .map((_, idx) => <BlockListRowLoading key={idx} />)}
            </Table>
        </>
    )
}

interface BlockListProps {
    className?: string
}

const BlockList = ({ className = '' }: BlockListProps) => {
    const { data, isLoading, loader, hasMore } = useBlockList(ITEMS_PER_FETCH)

    useEffect(() => {
        loader()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <BlockListDisplay
                className={className}
                blocks={data}
                isLoading={isLoading}
            />
            {hasMore && (
                <div className="w-full my-10 flex justify-center">
                    <LoadMoreButton
                        onClick={loader}
                        disabled={isLoading}
                        isLoading={isLoading}
                    />
                </div>
            )}
        </>
    )
}

export default BlockList
export { BlockListDisplay }
