import { useState, useEffect, useCallback } from 'react'
// api
import { getAccountStat, getAccountTransaction } from 'api'
import { AccountStatPayload, AccountTransactionItem } from 'api/types'

const useAccountStats = (id: string | undefined) => {
    const [isLoading, setLoading] = useState(true)
    const [hasError, setHasError] = useState(false)
    const [stat, setStat] = useState<AccountStatPayload>()

    useEffect(() => {
        if (typeof id !== 'string') {
            setLoading(false)
            setStat(undefined)
            return
        }

        setLoading(true)
        setHasError(false)

        getAccountStat(id)
            .then((data) => {
                setStat(data)
                setLoading(false)
            })
            .catch((err) => {
                console.error('ERROR: Cannot fetch data', err)
                setHasError(true)
                setLoading(false)
            })
    }, [id])

    return { stat, hasError, isLoading }
}

const useAccountTransaction = (
    id: string | undefined,
    count: number,
    startFrom: number
) => {
    const [isLoading, setLoading] = useState(true)
    const [hasError, setHasError] = useState(false)
    const [totalItem, setTotalItem] = useState(0)
    const [txData, setTxData] = useState<Array<AccountTransactionItem>>([])

    const loader = useCallback(() => {
        if (typeof id !== 'string') {
            setLoading(false)
            setTxData([])
            return
        }
        setLoading(true)
        setHasError(false)

        getAccountTransaction(id, count, startFrom)
            .then((payload) => {
                setTxData(payload.result)
                setTotalItem(payload.total)
                setLoading(false)
            })
            .catch((err) => {
                console.error('ERROR: Cannot fetch data', err)
                setHasError(true)
                setLoading(false)
            })
    }, [id, count, startFrom])

    useEffect(() => {
        loader()
    }, [loader]) // trigger on new loading argument (id, startFrom, ...)

    return { txData, totalItem, hasError, isLoading, loader }
}

export { useAccountStats, useAccountTransaction }
