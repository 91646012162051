import { useCallback, useState } from 'react'

// api
import { PaginationPayload } from 'api/types'
import { getTransactionList, getBlockList } from 'api'

const useListLoader = <T>(
    dataFetcher: (
        offset: number,
        startFrom?: number
    ) => Promise<PaginationPayload<T>>,
    idField: string // field name which represent unique id of each item
) => {
    const [isLoading, setLoadingState] = useState(false)
    const [hasMore, setHasMore] = useState(true)
    const [hasError, setHasError] = useState(false)
    const [data, setData] = useState<Array<T>>([])
    const [startFrom, setStartFrom] = useState<number>()

    const loader = useCallback(() => {
        setLoadingState(true)
        setHasError(false)
        dataFetcher(data.length, startFrom)
            .then((payload) => {
                const { result, total } = payload

                if (data.length === 0) {
                    const idOfFirstListItem = (result[0] as any)?.[
                        idField
                    ] as number
                    setStartFrom(idOfFirstListItem)
                }

                const nextData = [...data, ...result]
                setData(nextData)
                const hasMore = nextData.length < total - 1
                setHasMore(hasMore)
                setLoadingState(false)
            })
            .catch((err) => {
                console.log('ERROR: Cannot fetch data', err)
                setHasError(true)
                setLoadingState(false)
            })
    }, [dataFetcher, data, idField, startFrom, setStartFrom])

    return { data, hasMore, hasError, isLoading, loader }
}

const ITEMS_PER_FETCH = 20

export const useTransactionList = (itemsPerFetch = ITEMS_PER_FETCH) => {
    const txFetch = useCallback(
        async (offset: number, startFrom?: number) => {
            const result = await getTransactionList(
                itemsPerFetch,
                offset,
                startFrom
            )
            return result
        },
        [itemsPerFetch]
    )
    const { data, hasMore, isLoading, loader } = useListLoader(txFetch, 'id')
    return { data, hasMore, isLoading, loader }
}
export const useBlockList = (itemsPerFetch = ITEMS_PER_FETCH) => {
    const blockFetch = useCallback(
        async (offset: number, startFrom?: number) => {
            const result = await getBlockList(itemsPerFetch, offset, startFrom)
            return result
        },
        [itemsPerFetch]
    )
    const { data, hasMore, isLoading, loader } = useListLoader(
        blockFetch,
        'height'
    )
    return { data, hasMore, isLoading, loader }
}
