import React from 'react'

// assets
import { ReactComponent as Twitter } from 'images/icon-Twitter.svg'
import { ReactComponent as Linkedin } from 'images/icon-Linkedin.svg'

interface SocialIconProp {
    Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
    link: string
}

const SocialIcon = ({ Icon, link }: SocialIconProp) => (
    <a
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        className="
            text-2xl leading-6 text-on-surface-helper hover:text-surface
            h-9 w-9
            rounded-lg
            bg-surface hover:bg-on-surface
            flex items-center justify-center"
    >
        <Icon className="h-6" />
    </a>
)

interface SocialProp {
    title?: string
}

const Social = ({ title = 'Stay connected' }: SocialProp) => (
    <div
        className="
        text-base
        flex flex-col"
    >
        <p className="font-semibold">{title}</p>
        <div
            className="
                mr-auto mt-2 md:mt-6
                grid grid-rows-1 grid-flow-col gap-x-3"
        >
            <SocialIcon Icon={Twitter} link="https://twitter.com/BTQ_Tech" />
            <SocialIcon
                Icon={Linkedin}
                link="https://www.linkedin.com/company/btqag"
            />
        </div>
    </div>
)

export default Social
