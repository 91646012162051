import React from 'react'

import { CellProps } from 'components/Table/core'

interface FillerProps {
    className?: string
}

const Filler: React.FC<FillerProps> = ({ className = '', children }) => (
    <span
        className={`${className} text-transparent bg-on-surface-helper/30 select-none`}
    >
        {children}
    </span>
)

const EmptyBox = ({ className = '' }: { className?: string }) => (
    <div className={`${className} bg-on-surface-helper/30`}></div>
)

const TextPlaceholder = ({ className = '' }: { className?: string }) => (
    <Filler className={`block ${className}`}>Placeholder</Filler>
)

const HashPlaceholder = ({ className = '' }: { className?: string }) => (
    <div className={className}>
        <TextPlaceholder />
        <TextPlaceholder className="md:hidden" />
        <TextPlaceholder className="md:hidden" />
    </div>
)

const EmptyCell: React.FC<CellProps> = ({
    width,
    shrink = false,
    className = '',
    children,
    desc
}) => (
    <>
        {/*  Desktop / normal cell */}
        <div
            className={`
                hidden md:block
                p-2
                min-w-0
                ${className}`}
            style={{
                flex: `1 ${shrink ? 1 : 0} ${width}`
            }}
        >
            {children}
        </div>
        {/*  Mobile / info card */}
        <div
            className={`
                flex md:hidden
                text-xs text-left font-semibold
                ${className}`}
        >
            <div className="flex-none w-24">
                {desc ? desc : <EmptyBox className="w-full mr-2" />}
            </div>
            <div className="flex-auto">{children}</div>
        </div>
    </>
)

export { Filler, EmptyBox, TextPlaceholder, HashPlaceholder, EmptyCell }
