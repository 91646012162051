import { useMemo } from 'react'
import { getPageList } from './utils'

// assets
import { ReactComponent as AngleLeft } from 'images/icon-angle_left.svg'
import { ReactComponent as AngleRight } from 'images/icon-angle_right.svg'

enum AngleDirection {
    Next,
    Prev
}

const PageNavItem = ({
    page,
    active = false,
    onClick = () => {}
}: {
    page: string
    active?: boolean
    onClick?: () => void
}) => {
    return (
        <span
            className={`
                cursor-pointer hover:text-primary-500
                ${active ? 'font-bold text-primary-500 underline' : ''}`}
            onClick={onClick}
        >
            {page}
        </span>
    )
}

const PageNavEllipsis = () => (
    <span className="cursor-default select-none">...</span>
)

const PageNavAngle = ({
    direction,
    onClick = () => {}
}: {
    direction: AngleDirection
    onClick?: () => void
}) => (
    <>
        {direction === AngleDirection.Next ? (
            <AngleRight
                className="w-6 h-6 cursor-pointer hover:text-primary-500"
                onClick={onClick}
            />
        ) : (
            <AngleLeft
                className="w-6 h-6 cursor-pointer hover:text-primary-500"
                onClick={onClick}
            />
        )}
    </>
)

interface PaginationProps {
    totalItems: number
    itemsPerPage: number
    currentItem: number
    onPageSelected: (itemIndex: number) => void
    className?: string
}

const Pagination = ({
    totalItems,
    itemsPerPage,
    currentItem,
    onPageSelected,
    className = ''
}: PaginationProps) => {
    const totalPages = Math.ceil(totalItems / itemsPerPage)
    const currentPage = Math.ceil(currentItem / itemsPerPage) + 1
    const pageList = useMemo(
        () => getPageList(totalPages, currentPage, itemsPerPage),
        [totalPages, currentPage, itemsPerPage]
    )

    return (
        <div
            className={`
                text-sm font-semibold
                grid grid-flow-col
                gap-x-4 items-center justify-items-center
                ${className}`}
        >
            <PageNavAngle
                direction={AngleDirection.Prev}
                onClick={() =>
                    onPageSelected(Math.max(0, currentItem - itemsPerPage))
                }
            />
            {pageList.map((page, index) =>
                page.itemIndex !== undefined ? (
                    <PageNavItem
                        page={page.displayAs}
                        key={index}
                        active={page.num === currentPage}
                        onClick={() => onPageSelected(page.itemIndex as number)}
                    />
                ) : (
                    <PageNavEllipsis key={index} />
                )
            )}

            <PageNavAngle
                direction={AngleDirection.Next}
                onClick={() =>
                    onPageSelected(
                        Math.min(totalItems - 1, currentItem + itemsPerPage)
                    )
                }
            />
        </div>
    )
}

export default Pagination
