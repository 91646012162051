import {
    Panel,
    PanelRow,
    PanelCol,
    PanelHr,
    InfoItem,
    LargeInfoItem
} from 'components/Card/Panel'
import {
    Filler,
    TextPlaceholder,
    EmptyBox
} from 'components/Decoration/Fillter'

const TxInfoPanel = ({ className = '' }: { className?: string }) => (
    <Panel className={className}>
        <PanelRow className="animate-pulse">
            <PanelCol>
                <InfoItem
                    desc={<Filler>Height</Filler>}
                    content={<TextPlaceholder className="w-full" />}
                />
                <InfoItem
                    desc={<Filler>Timestamp</Filler>}
                    content={<TextPlaceholder className="w-full" />}
                />
                <InfoItem
                    desc={<Filler>Sender address</Filler>}
                    content={<TextPlaceholder className="w-full" />}
                />
                <InfoItem
                    desc={<Filler>Status</Filler>}
                    content={<TextPlaceholder className="w-full" />}
                />
                <InfoItem
                    desc={<Filler>Amount</Filler>}
                    content={<TextPlaceholder className="w-full" />}
                />
                <InfoItem
                    desc={<Filler>Nonce for sender</Filler>}
                    content={<TextPlaceholder className="w-full" />}
                />
            </PanelCol>
            <PanelCol className="hidden md:grid">
                <LargeInfoItem
                    desc={<Filler>Signature</Filler>}
                    content={<EmptyBox className="h-full" />}
                />
            </PanelCol>
        </PanelRow>

        <PanelHr />

        <PanelRow className="hidden md:grid animate-pulse">
            <PanelCol>
                <div className={`w-full flex ${className}`}>
                    {/* sender */}
                    <div className="flex-1">
                        <div className="flex">
                            <InfoItem
                                className="flex-1"
                                desc={<Filler>Sender</Filler>}
                                content={<TextPlaceholder className="w-full" />}
                            />
                            <InfoItem
                                className="ml-3"
                                desc={<Filler>Amount</Filler>}
                                content={<TextPlaceholder className="w-full" />}
                            />
                        </div>
                    </div>
                    {/* arrow */}
                    <div
                        className="
                            flex-none h-7 w-16
                            flex items-center justify-center"
                    ></div>
                    {/* receiver */}
                    <div className="flex-1">
                        <div className="flex">
                            <InfoItem
                                className="flex-1"
                                desc={<Filler>Receiver</Filler>}
                                content={<TextPlaceholder className="w-full" />}
                            />
                            <InfoItem
                                className="ml-3"
                                desc={<Filler>Amount</Filler>}
                                content={<TextPlaceholder className="w-full" />}
                            />
                        </div>
                    </div>
                </div>
            </PanelCol>
        </PanelRow>
    </Panel>
)

export { TxInfoPanel }
