import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { DecodedAggregateSignature } from './DecodedAggregateSignature'

// components
import {
    InfoItem,
    LargeInfoItem,
    PanelCol,
    PanelRow,
    Panel,
    PanelHr
} from 'components/Card/Panel'
import { Filler, TextPlaceholder } from 'components/Decoration/Fillter'
import { BlockDetail } from 'api/types'
import { LiveTimestampDisplay } from 'components/display/Timestamp'
import { displayHexInDecimal } from 'utils/unitConversion'
import { timestampToDate } from 'utils/createDate'

interface BlockInfoPaneProps {
    blockDetail?: BlockDetail
    className?: string
    isLoading?: boolean
}

const BlockInfoPanelOK = ({
    blockDetail,
    className = ''
}: BlockInfoPaneProps) => {
    const { t } = useTranslation()

    const blockDate = useMemo(
        () => blockDetail?.timestamp && timestampToDate(blockDetail?.timestamp),
        [blockDetail?.timestamp]
    )

    return (
        <Panel className={className}>
            <PanelRow>
                <PanelCol>
                    {/* <InfoItem
                        desc={t('blockinfo_panel_height')}
                        content={blockDetail?.number || ''}
                    /> */}
                    <InfoItem
                        desc={'Base Fee Per Gas'}
                        content={displayHexInDecimal(
                            blockDetail?.baseFeePerGas
                        )}
                    />
                    <InfoItem
                        desc={'Difficuly'}
                        content={displayHexInDecimal(blockDetail?.difficulty)}
                    />

                    <InfoItem
                        desc={'Gas Limit'}
                        content={displayHexInDecimal(blockDetail?.gasLimit)}
                    />
                    <InfoItem
                        desc={'Gas Used'}
                        content={displayHexInDecimal(blockDetail?.gasUsed)}
                    />
                </PanelCol>
                <PanelCol className="md:grid">
                    <InfoItem
                        desc={t('blockinfo_panel_timestamp')}
                        content={
                            <>
                                {blockDate && (
                                    <span className="inline-block">
                                        {/* {blockDetail?.timestamp} */}
                                        <LiveTimestampDisplay
                                            value={blockDate}
                                        />
                                    </span>
                                )}
                            </>
                        }
                    />
                    <InfoItem
                        desc={'Nonce'}
                        content={displayHexInDecimal(blockDetail?.nonce)}
                    />

                    <InfoItem
                        desc={'Size'}
                        content={displayHexInDecimal(blockDetail?.size)}
                    />

                    <InfoItem
                        desc={'Total Difficulty'}
                        content={displayHexInDecimal(
                            blockDetail?.totalDifficulty
                        )}
                    />
                </PanelCol>
            </PanelRow>
            <PanelHr />
            <PanelRow>
                <PanelCol>
                    <InfoItem
                        desc={'Miner'}
                        content={blockDetail?.miner || ''}
                    />
                    <InfoItem
                        desc={'Sha3 Uncles'}
                        content={blockDetail?.sha3Uncles || ''}
                    />
                    <InfoItem
                        desc={'mixHash'}
                        content={blockDetail?.mixHash || ''}
                    />
                    <InfoItem
                        desc={'State Root'}
                        content={blockDetail?.stateRoot || ''}
                    />
                    <InfoItem
                        desc={'Transaction Root'}
                        content={blockDetail?.transactionsRoot || ''}
                    />
                    <InfoItem
                        desc={'Parent Hash'}
                        content={blockDetail?.parentHash || ''}
                    />
                    <InfoItem
                        desc={'Logs Bloom'}
                        content={blockDetail?.logsBloom}
                    />
                    <InfoItem
                        desc={'Receipts Root'}
                        content={blockDetail?.receiptsRoot || ''}
                    />
                    <InfoItem
                        showCopyIcon
                        desc={'Extra Data'}
                        content={blockDetail?.extraData}
                    />
                    {/* TODO: Decode the RAW data and show detail information */}
                    <InfoItem
                        showCopyIcon
                        desc={'Meta Data'}
                        content={blockDetail?.aggregateSignature}
                    />
                    <DecodedAggregateSignature
                        signature={blockDetail?.aggregateSignature}
                    />
                </PanelCol>
            </PanelRow>
        </Panel>
    )
}

const BlockInfoPanelLoading = ({ className = '' }: { className?: string }) => (
    <Panel className={className}>
        <PanelRow className="animate-pulse">
            <PanelCol>
                <InfoItem
                    desc={<Filler>Height</Filler>}
                    content={<TextPlaceholder className="w-full" />}
                />
                <InfoItem
                    desc={<Filler>Timestamp</Filler>}
                    content={<TextPlaceholder className="w-full" />}
                />
                <InfoItem
                    desc={<Filler>Size</Filler>}
                    content={<TextPlaceholder className="w-full" />}
                />
            </PanelCol>
            <PanelCol className="hidden md:grid">
                <LargeInfoItem
                    desc={<Filler>Aggregated Signature</Filler>}
                    content={<TextPlaceholder className="w-full h-full" />}
                />
            </PanelCol>
        </PanelRow>
    </Panel>
)

const BlockInfoPanel = ({
    isLoading,
    className = '',
    ...props
}: BlockInfoPaneProps) =>
    isLoading ? (
        <BlockInfoPanelLoading className={className} />
    ) : (
        <BlockInfoPanelOK className={className} {...props} />
    )

export { BlockInfoPanel }
