import React from 'react'
import {
    CellProps as BaseCellProps,
    RowProps,
    TableProps
} from 'components/Table/core'
import { GradientText } from 'components/Text'
import { TextPlaceholder } from 'components/Decoration/Fillter'

interface CellProps extends Omit<BaseCellProps, 'width'> {
    width: [string, string] // initial width
}

const Cell: React.FC<CellProps> = ({
    width,
    shrink = false,
    className = '',
    children = <TextPlaceholder className="mx-2" />
}) => (
    <>
        {/* normal cell */}
        <div
            className={`
                hidden md:block
                p-2
                min-w-0
                ${className}`}
            style={{
                flex: `1 ${shrink ? 1 : 0} ${width[0]}`
            }}
        >
            {children}
        </div>
        {/*  mobile cell */}
        <div
            className={`
                block md:hidden
                p-2
                min-w-0
                ${className}`}
            style={{
                flex: `1 0 ${width[1]}`
            }}
        >
            {children}
        </div>
    </>
)

const HeaderCell: React.FC<CellProps> = ({ children, ...restProps }) => (
    <Cell {...restProps}>
        <GradientText>{children}</GradientText>
    </Cell>
)

const Row: React.FC<RowProps> = ({ onClick, className = '', children }) => (
    <div
        className={`
            text-xs md:text-base font-bold
            md:w-full
            h-9 md:h-20 md:px-3
            flex items-center
            rounded-md shadow-lg
            bg-surface
            ${
                onClick
                    ? 'cursor-pointer hover:bg-background active:bg-surface'
                    : ''
            }
            ${className}`}
        onClick={onClick}
    >
        {children}
    </div>
)

const Header: React.FC<RowProps> = ({ className = '', children }) => (
    <div
        className={`
            text-sm md:text-xl font-semibold
            md:w-full
            md:px-3
            flex items-center
            ${className}`}
    >
        {children}
    </div>
)

const Table: React.FC<TableProps> = ({ className = '', children }) => (
    <div
        className={`
            text-center
            min-w-full
            overflow-x-auto pb-3 md:pb-0
            grid auto-cols-fr
            gap-y-2 md:gap-y-4
            ${className}`}
    >
        {children}
    </div>
)

export { Cell, HeaderCell, Row, Header, Table }
