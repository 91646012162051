// import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

// components
import Section from 'components/layout/Section'
import Helmet from 'components/Helmet'
import { GeneralButton } from 'components/Button'

// assets
import { ReactComponent as NotFound } from 'images/not_found.svg'

const NotFoundPage = () => {
    // const { t } = useTranslation()
    const navigate = useNavigate()

    return (
        <>
            <Helmet title="Not Found" />
            <Section className="mt-5 md:mt-12">
                <div className="flex flex-col items-center">
                    <h1 className="text-4xl font-bold">No result found</h1>
                    <span className="text-base font-semibold text-on-surface-helper">
                        Sorry! No results match the search term
                    </span>
                    <NotFound className="mt-9" />
                </div>
                <div className="mt-6 md:mt-16 flex justify-center">
                    <GeneralButton
                        className="h-12 w-36"
                        onClick={() => {
                            if (window.history.length > 1) {
                                navigate(-1)
                            } else {
                                navigate('/')
                            }
                        }}
                    >
                        Back to explorer
                    </GeneralButton>
                </div>
            </Section>
        </>
    )
}

export default NotFoundPage
