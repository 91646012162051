import React, { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// comonents
import { BasicInfoItem, BasicInfoItemProps } from 'components/Card/core'
import { ElipseableText, GradientText } from 'components/Text'
import { CurrencyDisplay } from 'components/display/Currency'
import { TxCardLoading } from './TransactionLoading'

// assets
import { ReactComponent as ArrowRight } from 'images/gradient-arrow_right.svg'
import { ReactComponent as AngleRight } from 'images/icon-angle_right.svg'
import { ReactComponent as BadgeCheck } from 'images/gradient-badge-check.svg'

interface ClickableHeightProps {
    blockHeight: number
    className?: string
}

const ClickableHeight: FC<ClickableHeightProps> = ({
    blockHeight,
    className = ''
}) => {
    const navigate = useNavigate()
    return (
        <span
            className={`cursor-pointer hover:text-primary-500 ${className}`}
            onClick={(event) => {
                navigate(`/blocks/${blockHeight}`)
                event.stopPropagation()
            }}
        >
            {blockHeight}
        </span>
    )
}

interface ClickableAddressProps {
    address: string
    className?: string
}

const ClickableAddress: FC<ClickableAddressProps> = ({
    address,
    className = ''
}) => {
    const navigate = useNavigate()
    return (
        <span
            className={`w-full min-w-0 cursor-pointer hover:text-primary-500 ${className}`}
            onClick={(event) => {
                navigate(`/account/${address}`)
                event.stopPropagation()
            }}
        >
            <ElipseableText value={address} />
        </span>
    )
}

const BadgeVerified = ({ className = '' }: { className?: string }) => (
    <div
        className={`
            text-base font-semibold text-primary-500
            flex
            ${className}`}
    >
        <BadgeCheck className="w-5" />
        <span className="ml-1">Verified</span>
    </div>
)

const TxInfoItem = ({
    className = '',
    descClassName = '',
    contentClassName = '',
    ...props
}: BasicInfoItemProps) => (
    <BasicInfoItem
        {...props}
        className={`font-semibold ${className}`}
        descClassName={`text-xs md:text-xl ${descClassName}`}
        contentClassName={`text-xs md:text-xs ${contentClassName}`}
    />
)

interface TxAmountHeaderProps {
    role: 'send' | 'recv'
    className?: string
}

const TxAmountHeader = ({ role, className = '' }: TxAmountHeaderProps) => {
    const { t } = useTranslation()

    return (
        <div
            className={`
                hidden md:flex
                text-xl font-semibold
                ${className}`}
        >
            <div className="flex-1 flex justify-start">
                <GradientText>
                    {t(
                        { send: 'txcard_sender', recv: 'txcard_receiver' }[role]
                    )}
                </GradientText>
            </div>
            <div className="w-24 flex justify-center">
                <GradientText>{t('txcard_amount')}</GradientText>
            </div>
        </div>
    )
}

interface TxAmountRowProps {
    account: string
    amount: string
    role: 'send' | 'recv'
    className?: string
}

const TxAmountRow = ({
    account,
    amount,
    role,
    className = ''
}: TxAmountRowProps) => {
    const { t } = useTranslation()

    return (
        <div className={`contents md:flex md:flex-row ${className}`}>
            <TxInfoItem
                className="md:flex-1"
                descClassName="md:hidden"
                desc={t(
                    { send: 'txcard_sender', recv: 'txcard_receiver' }[role]
                )}
                content={<ClickableAddress address={account} />}
            />
            <TxInfoItem
                className="md:w-24 md:flex-none md:ml-5"
                descClassName="md:hidden"
                desc={t('txcard_amount')}
                contentClassName="md:flex md:justify-center"
                content={<CurrencyDisplay wei={amount} />}
            />
        </div>
    )
}

type TxAmount = {
    account: string
    amount: string
}

interface SendRecvProps {
    from: TxAmount[]
    to: TxAmount[]
    className?: string
}

const SendRecv = ({ from, to, className = '' }: SendRecvProps) => (
    <div className={`contents md:flex ${className}`}>
        {/* sender */}
        <div
            className="
                contents
                flex-1
                md:flex flex-col space-y-3"
        >
            <TxAmountHeader role="send" />
            {from.map(({ account, amount }) => (
                <TxAmountRow
                    key={account}
                    account={account}
                    amount={amount}
                    role="send"
                />
            ))}
        </div>
        {/* arrow */}
        <div
            className="
                hidden md:flex
                flex-none h-7 w-16
                items-center justify-center"
        >
            <ArrowRight className="w-h h-6" />
        </div>
        {/* receiver */}
        <div
            className="
                contents
                flex-1
                md:flex flex-col space-y-3"
        >
            <TxAmountHeader role="recv" />
            {to.map(({ account, amount }) => (
                <TxAmountRow
                    key={account}
                    account={account}
                    amount={amount}
                    role="recv"
                />
            ))}
        </div>
    </div>
)

interface TxCardProps {
    hash: string
    from: TxAmount[]
    to: TxAmount[]
    verified?: boolean
    className?: string
}

const TxCard = ({
    hash,
    from,
    to,
    verified = false,
    className = ''
}: TxCardProps) => {
    const navigate = useNavigate()
    const { t } = useTranslation()

    return (
        <div
            className={`
                w-full relative
                py-6 pl-5 pr-10
                bg-surface rounded-md shadow-lg
                hover:bg-background active:bg-surface
                cursor-pointer ${className}`}
            onClick={() => navigate(`/transactions/${hash}`)}
        >
            <div className="w-full grid gap-y-2 md:gap-y-4">
                <TxInfoItem
                    desc={t('txcard_transaction_id')}
                    content={<ElipseableText className="w-full" value={hash} />}
                    contentClassName="md:mt-3"
                />
                <SendRecv className="w-full" from={from} to={to} />
            </div>
            <AngleRight className="absolute w-6 h-6 right-2 bottom-1/2 translate-y-1/2" />
            {verified && <BadgeVerified className="absolute top-7 right-7" />}
        </div>
    )
}

interface TxCardLayoutProps {
    className?: string
}

const TxCardLayout: React.FC<TxCardLayoutProps> = ({
    className = '',
    children
}) => <div className={`w-full grid gap-y-4 ${className}`}>{children}</div>

export {
    ClickableAddress,
    ClickableHeight,
    TxInfoItem,
    SendRecv,
    TxCard,
    TxCardLoading,
    TxCardLayout
}
