import React, { ButtonHTMLAttributes } from 'react'

const GeneralButton: React.FC<ButtonHTMLAttributes<HTMLButtonElement>> = ({
    children,
    onClick = () => {},
    className = '',
    ...props
}) => (
    <button
        className={`
            text-sm font-semibold
            h-12
            px-4
            rounded-md
            bg-gradient-to-br from-primary-alt-500 to-primary-500 
            text-on-primary
            hover:from-background hover:to-background
            hover:text-on-background
            active:from-primary-alt-500 active:to-primary-500 
            active:text-on-primary
            ${className}`}
        onClick={onClick}
        {...props}
    >
        {children}
    </button>
)

const GhostButton: React.FC<ButtonHTMLAttributes<HTMLButtonElement>> = ({
    children,
    onClick = () => {},
    className = '',
    ...props
}) => (
    <button
        className={`
            text-sm font-semibold
            h-12
            px-4
            rounded-md
            border-2 border-primary-500
            hover:bg-primary-500 hover:border-0 
            hover:text-on-primary
            active:bg-background active:border-0 
            active:text-on-background
            ${className}`}
        onClick={onClick}
        {...props}
    >
        {children}
    </button>
)

// Ghost Button with pre-definded width & text
const LoadMoreButton: React.FC<
    ButtonHTMLAttributes<HTMLButtonElement> & { isLoading?: boolean }
> = ({ isLoading = false, className = '', ...props }) => (
    <GhostButton {...props} className={`w-40 ${className}`}>
        {isLoading ? 'Loading...' : 'Load More'}
    </GhostButton>
)

export { GeneralButton, GhostButton, LoadMoreButton }
